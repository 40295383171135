import { Link } from "react-router-dom";
import { ActionItemCounter, ActionItemKPI } from "../FindingsStyles";
import { SkeletonLoading } from "../../../components/elements/loading/SkeletonLoading";
import { Flex } from "../../../components/layouts/flex/Flex";
import { LabelRegularHover } from "../../../components/elements/typography/Typography";
import { Tooltip } from "../../../components/elements/tooltip/Tooltip";
import { Icon } from "../../../components/elements/icon/Icon";
import { FindingsCounts } from "../../../types/Finding";
import { ThemeContext } from "styled-components";
import { useContext } from "react";
import { useScreenWidth } from "../../../hooks/utilsHooks";
import { SCREEN_MOBILE_WIDTH } from "../../../shared/consts";

type Props = {
  title: string;
  dataTestId: string;
  tooltipContent?: string;
  countsKey: string;
  onClick: () => void;
  linkTo: string;
  isFetching: boolean;
  findingsCounts?: FindingsCounts;
};

export const ActionItem = ({
  title,
  dataTestId,
  tooltipContent,
  countsKey,
  onClick,
  linkTo,
  isFetching,
  findingsCounts,
}: Props) => {
  const theme = useContext(ThemeContext);
  const screenWidth = useScreenWidth();
  const isMobile = screenWidth < SCREEN_MOBILE_WIDTH;

  return (
    <Link
      to={isMobile ? "/" : linkTo}
      style={{
        textDecoration: "none",
        color: "inherit",
        width: "100%",
      }}
    >
      <ActionItemCounter
        data-testid={dataTestId}
        onClick={onClick}
        className="w-100"
      >
        {isFetching ? (
          <SkeletonLoading height="48px" width="48px" />
        ) : (
          <ActionItemKPI
            value={
              (findingsCounts &&
                findingsCounts[countsKey as keyof FindingsCounts]) ||
              0
            }
          >
            {findingsCounts &&
              findingsCounts[countsKey as keyof FindingsCounts]}
          </ActionItemKPI>
        )}
        <Flex align="center" gap="8px">
          <LabelRegularHover
            className="text-truncate w-100 text-center"
            title={title}
          >
            {title}
          </LabelRegularHover>
          {!isMobile && tooltipContent && (
            <Tooltip content={tooltipContent}>
              <Icon name="info" color={theme.black600} size={16} />
            </Tooltip>
          )}
        </Flex>
      </ActionItemCounter>
    </Link>
  );
};
