import { useContext } from "react";
import { Finding, FindingStatus } from "../types/Finding";
import { NotificationReceiver } from "../types/Notifications";
import { Project } from "../types/Project";
import { Insights, InsightsMapping, StatusMapping } from "./consts";
import { ThemeContext } from "styled-components";

export function getProject(
  finding?: Finding | null,
  projects?: Project[]
): Project | undefined {
  if (!finding || !projects) return undefined;

  return projects.find((project) => project.id === finding.project);
}

export function getFindingInsight(finding?: Finding | null): string {
  if (!finding) return "";

  return getInsight(finding.mitigation_complexity, finding.impact);
}
export function getSLATitle(leftDays?: number | null): string {
  if (leftDays === null || leftDays === undefined) return "N/A";
  else if (leftDays === 0) return "Breach today";
  else if (leftDays === 1) return "Breach tomorrow";
  else if (leftDays === -1) return "1 Day overdue";
  else if (leftDays > 1) return `${leftDays} Days left`;
  else if (leftDays < -1) return `${leftDays * -1} Days overdue`;

  return "N/A";
}

export function getStatusName(status: FindingStatus): string {
  return StatusMapping[status];
}

type RiskColor =
  | "grayBluePrimary"
  | "yellowPrimary"
  | "orangePrimary"
  | "redPrimary"
  | "brownPrimary"
  | "grayBluePrimary";

export const getRiskColor = (risk: number): RiskColor => {
  if (risk < 1) return "grayBluePrimary";
  if (risk === 1) return "yellowPrimary";
  if (risk === 2) return "orangePrimary";
  if (risk === 3) return "redPrimary";
  if (risk > 3) return "brownPrimary";
  return "grayBluePrimary";
};

export type RiskColorsMap = {
  total?: string;
  info?: string;
  low: string;
  medium: string;
  high: string;
  critical: string;
};

export const useRiskColors = ({
  withInfo,
  withTotal,
}: {
  withInfo?: boolean;
  withTotal?: boolean;
}) => {
  const theme = useContext(ThemeContext);
  let riskColors: RiskColorsMap = {
    low: theme.yellowPrimary,
    medium: theme.orangePrimary,
    high: theme.redPrimary,
    critical: theme.brownPrimary,
  };
  if (withInfo) riskColors.info = theme.gray600;
  if (withTotal) riskColors.total = theme.primary;
  return { riskColors };
};

export function getInsight(
  mitigation_complexity: number | null | undefined,
  impact: number | null | undefined
): string {
  if (mitigation_complexity == null || impact == null) {
    return Insights.MustHave;
  }

  if (mitigation_complexity < 2 && impact < 2) {
    return Insights.LowHangingFruits;
  }

  if (mitigation_complexity < 2 && impact >= 2) {
    return Insights.QuickWin;
  }

  if (mitigation_complexity >= 2 && impact < 2) {
    return Insights.MoneyPits;
  }

  return Insights.MustHave;
}

export function toInsight(
  impacts: number[],
  mitigationComplexities: number[]
): Insights | null {
  if (!mitigationComplexities?.length || !impacts?.length) return null;

  const isLowComplexity = mitigationComplexities.some((x) => x < 2);
  const isLowImpact = impacts.some((x) => x < 2);

  // DB: I've made this more explicit than it needs to be for readability

  if (isLowComplexity && isLowImpact) {
    return Insights.LowHangingFruits;
  }

  if (isLowComplexity && !isLowImpact) {
    return Insights.QuickWin;
  }

  if (!isLowComplexity && isLowImpact) {
    return Insights.MoneyPits;
  }

  if (!isLowComplexity && !isLowImpact) {
    return Insights.MustHave;
  }

  // This line should never be reached, but TypeScript requires a return statement
  // that covers all possible code paths
  return null;
}

export function fromInsight(insight: Insights): {
  impact: number[];
  mitigationComplexities: number[];
} {
  const insightMapping = InsightsMapping[insight];

  if (!insightMapping) {
    throw new Error(`Unknown insight: ${insight}`);
  }

  return {
    impact: insightMapping.impacts,
    mitigationComplexities: insightMapping.mitigationComplexities,
  };
}

export function getFindingFromNotificationReceiver(
  notification: NotificationReceiver,
  findings: Finding[]
) {
  return findings.find(
    (finding) =>
      notification.notification_event?.event_details["finding_id"] ===
      finding.id
  );
}
