import { useParams } from "react-router";
import { useApiMe } from "../../../../hooks/queries/meContext";
import { useApiScanners } from "../../../../hooks/queries/scannersContext";
import {
  FormModeState,
  useUpdateFindingInPlace,
} from "../../../../shared/formUtils";
import { capitalize } from "../../../../shared/helper";
import {
  AdminFindingEdit,
  AutomatedFindingType,
  Finding,
  FindingType,
  findingTypesOptions,
} from "../../../../types/Finding";
import { Dispatch, SetStateAction, useState } from "react";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { LabelRegular } from "../../../../components/elements/typography/Typography";
import { RequiredField } from "../../../../components/elements/requiredField/RequiredField";
import { Dropdown } from "../../../../components/elements/dropdowns/Dropdown";
import { SeparatorVertical } from "../../../../components/elements/separators/SeparatorVertical";

type Props = {
  formMode: FormModeState;
  createFindingData?: AdminFindingEdit;
  setCreateFinding?: Dispatch<SetStateAction<AdminFindingEdit>>;
  setUpdateFinding?: Dispatch<SetStateAction<Finding | undefined>>;
  editableUpdateFindingData?: AdminFindingEdit | null;
  setEditableUpdateFinding?: Dispatch<SetStateAction<AdminFindingEdit | null>>;
  setCachedFindingData?: (data: AdminFindingEdit) => void;
};

export const IsAutomatedAndScanner = (props: Props) => {
  const {
    formMode,
    createFindingData,
    setCreateFinding,
    setUpdateFinding,
    editableUpdateFindingData,
    setEditableUpdateFinding,
    setCachedFindingData,
  } = props;

  const { data: me } = useApiMe();
  const { id: updateFindingId } = useParams();

  //  Scanners Options
  const { data: scanners } = useApiScanners(me?.customer?.id);
  let scannersOptions =
    scanners?.map((s) => ({ label: capitalize(s.name), value: s.name })) || [];
  scannersOptions.push({ value: "wasp", label: "WASP" });

  const [showScannerSelect, setShowScannerSelect] = useState<boolean>(
    formMode === FormModeState.Update
      ? !!editableUpdateFindingData?.is_automated
      : !!createFindingData?.is_automated
  );

  const { updateInPlace, queryStatus, changedField } = useUpdateFindingInPlace(
    parseInt(updateFindingId ? updateFindingId : "0"),
    setUpdateFinding
  );

  return (
    <Flex gap="12px" align="center" style={{ height: "32px" }}>
      <Flex gap="8px" align="center">
        <Flex>
          <LabelRegular>Finding Type:</LabelRegular>
        </Flex>
        <Dropdown
          variant="outline"
          options={findingTypesOptions}
          queryStatus={queryStatus}
          value={findingTypesOptions.find((opt) =>
            formMode === FormModeState.Update
              ? opt.value === editableUpdateFindingData?.finding_type
              : opt.value === createFindingData?.finding_type
          )}
          onChange={(opt) => {
            if (formMode === FormModeState.Update) {
              setEditableUpdateFinding &&
                setEditableUpdateFinding((prev) => ({
                  ...prev,
                  is_automated: AutomatedFindingType.includes(
                    opt?.value.toString() || ""
                  ),
                  scanner_name: "wasp",
                  finding_type: (opt?.value?.toString() || "") as FindingType,
                }));
              updateInPlace({
                is_automated: AutomatedFindingType.includes(
                  opt?.value.toString() || ""
                ),
                finding_type: opt?.value,
              });
              setCachedFindingData &&
                opt?.value &&
                setCachedFindingData({
                  finding_type: `${opt.value}` as FindingType,
                  is_automated: AutomatedFindingType.includes(
                    opt?.value.toString() || ""
                  ),
                });
            }
            if (formMode === FormModeState.Create) {
              setCreateFinding &&
                setCreateFinding((prev) => ({
                  ...prev,
                  is_automated: AutomatedFindingType.includes(
                    opt?.value.toString() || ""
                  ),
                  scanner_name: "wasp",
                  finding_type: `${opt?.value}` as FindingType,
                }));
            }
            setShowScannerSelect(
              AutomatedFindingType.includes(opt?.value.toString() || "")
            );
          }}
          placeholder="Select Finding Type"
        />
      </Flex>
      {showScannerSelect && (
        <Flex gap="8px" align="center">
          <SeparatorVertical height="24px" />
          <Flex>
            <LabelRegular>Scanner</LabelRegular>
            <RequiredField />
          </Flex>
          <Dropdown
            placeholder="Select Scanner"
            variant="border"
            options={scannersOptions}
            value={scannersOptions.find((opt) =>
              formMode === FormModeState.Update
                ? opt.value === editableUpdateFindingData?.scanner_name
                : opt.value === createFindingData?.scanner_name
            )}
            onChange={(opt) => {
              if (formMode === FormModeState.Update) {
                setEditableUpdateFinding &&
                  setEditableUpdateFinding((prev) => ({
                    ...prev,
                    scanner_name: `${opt?.value}`,
                  }));
                updateInPlace({ scanner_name: opt?.value });
                setCachedFindingData &&
                  opt?.value &&
                  setCachedFindingData({ scanner_name: `${opt.value}` });
              }
              if (formMode === FormModeState.Create) {
                setCreateFinding &&
                  setCreateFinding((prev) => ({
                    ...prev,
                    scanner_name: `${opt?.value}`,
                  }));
              }
            }}
            disabled={queryStatus !== "idle"}
            queryStatus={
              changedField === "scanner_name" ? queryStatus : undefined
            }
          />
        </Flex>
      )}
    </Flex>
  );
};
