import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { Flex } from "../../layouts/flex/Flex";
import { LabelRegular, LabelRegularHover } from "../typography/Typography";
import { RiskColorsMap, useRiskColors } from "../../../shared/findingsHelper";

function RiskLegendItem({
  label,
  size,
  isDisabled,
  handleLegendClick,
}: {
  label: string;
  size?: number;
  isDisabled?: boolean | undefined;
  handleLegendClick?: Function;
}) {
  const theme = useContext(ThemeContext);
  const { riskColors } = useRiskColors({ withInfo: true, withTotal: true });

  const dotStyle = {
    backgroundColor: riskColors[label?.toLowerCase() as keyof RiskColorsMap],
    border: `3px solid ${riskColors[label?.toLowerCase() as keyof RiskColorsMap]}`,
    borderRadius: "50%",
    width: "8px",
    height: "8px",
  };

  return (
    <Flex
      gap="8px"
      align="center"
      data-testid={label}
      style={{
        fontSize: size || "12px",
        textDecoration: isDisabled ? "line-through" : "none",
        cursor: handleLegendClick && !isDisabled ? "pointer" : "default",
        textTransform: "capitalize",
        overflowY: "clip",
      }}
      onClick={() => handleLegendClick && handleLegendClick(label)}
    >
      <div style={dotStyle}></div>
      {handleLegendClick && !isDisabled ? (
        <LabelRegularHover style={{ fontSize: size }}>
          {label}
        </LabelRegularHover>
      ) : (
        <LabelRegular
          style={{
            fontSize: size,
            fontWeight: size && size > 14 ? "600" : "400",
            color: size && size > 14 ? theme.textHeader : theme.textBody,
          }}
          color={theme.textSecondary}
        >
          {label}
        </LabelRegular>
      )}
    </Flex>
  );
}

export default RiskLegendItem;
