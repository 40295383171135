import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { Box } from "../../../../components/elements/box/Box";
import { TextButton } from "../../../../components/elements/button/text/TextButton";
import { Icon } from "../../../../components/elements/icon/Icon";
import { SeparatorVertical } from "../../../../components/elements/separators/SeparatorVertical";
import { HeaderSecondary } from "../../../../components/elements/typography/Typography";
import { useApiFindingsCounts } from "../../../../hooks/queries/findingContext";
import { useScreenWidth } from "../../../../hooks/utilsHooks";
import {
  SCREEN_DESKTOP_WIDTH,
  SCREEN_LAPTOP_WIDTH,
  SCREEN_MEDIUM_LAPTOP_WIDTH,
} from "../../../../shared/consts";

import { DefaultFindingsCounts } from "../../../../types/Finding";
import { FindingsOverview } from "./FindingsOverview";
import { AssetsOverview } from "./AssetsOverview";
import { Link } from "react-router-dom";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { objectToBase64 } from "../../../../shared/helper";
import { findingsDefaultFilters } from "../../../findings/Findings";
import { Mixpanel } from "../../../../shared/mixpanel";
import { SeparatorHorizontal } from "../../../../components/elements/separators/SeparatorHorizontal";

type Props = {
  selectedProduct: number | "all";
};

export const DashboardOverviewCard = (props: Props) => {
  const { selectedProduct } = props;
  const theme = useContext(ThemeContext);
  const screenWidth = useScreenWidth();
  const isDesktop = screenWidth >= SCREEN_DESKTOP_WIDTH;
  const isLaptop = screenWidth < SCREEN_MEDIUM_LAPTOP_WIDTH;
  const isBigLaptop = screenWidth < SCREEN_LAPTOP_WIDTH;

  const getFindingsCountParams = () => {
    var params: any = {
      status: "presented,re_test,in_progress",
    };
    if (selectedProduct !== "all") params.product = selectedProduct;

    return params;
  };

  const { data: findingsCounts, isFetching } = useApiFindingsCounts(
    getFindingsCountParams()
  );

  return (
    <Box
      data-tut="dashboard-overview"
      className={`d-flex  ${isDesktop ? "gap-24" : "gap-16"}`}
      style={{
        padding: "24px",
        flexGrow: 1,
        overflow: "hidden",
        flexDirection: isLaptop ? "column" : "row",
      }}
    >
      <Flex
        column
        gap="24px"
        style={{
          width: isBigLaptop ? "100%" : "40%",
          overflow: "hidden",
        }}
      >
        <Flex justify="between" align="center" gap="8px" w100>
          <Flex align="center" gap="8px" className="text-truncate">
            <Icon name="insightsOutline" size={32} color={theme.primary} />
            <HeaderSecondary
              className="text-truncate"
              title="Findings Overview"
            >
              Findings Overview
            </HeaderSecondary>
          </Flex>
          <Link
            to={`/findings?filters=${objectToBase64({
              ...findingsDefaultFilters,
              product: selectedProduct,
            })}`}
            style={{
              textDecoration: "none",
              padding: "0px",
              overflow: "auto",
              maxWidth: "60px",
              flexShrink: 0,
            }}
            className="text-truncate"
          >
            <TextButton
              label="See All"
              onClick={() => {
                Mixpanel.track("Dashboard - Overview Card - See All");
              }}
              style={{ padding: "3px" }}
            />
          </Link>
        </Flex>
        <FindingsOverview
          findingsCounts={findingsCounts || DefaultFindingsCounts}
          isFetching={isFetching}
          selectedProduct={selectedProduct}
        />
      </Flex>
      {isLaptop ? <SeparatorHorizontal /> : <SeparatorVertical />}
      <Flex
        w100
        style={{
          width: isBigLaptop ? "100%" : "60%",
          overflow: "hidden",
        }}
      >
        <AssetsOverview selectedProduct={selectedProduct} />
      </Flex>
    </Box>
  );
};
