import { useContext } from "react";
import { Badge } from "../../../components/elements/badge/Badge";
import { Icon } from "../../../components/elements/icon/Icon";
import { LabelMini } from "../../../components/elements/typography/Typography";
import { ThemeContext } from "styled-components";
import { Flex } from "../../../components/layouts/flex/Flex";

type Props = {
  oldValue: number;
  newValue: number;
  timeframeLabel: string;
  showTrendIcon?: boolean;
};

export const TrendBadge = (props: Props) => {
  const theme = useContext(ThemeContext);
  const { oldValue, newValue, timeframeLabel, showTrendIcon } = props;

  const isTrendPositive = newValue > oldValue;

  const diff = isTrendPositive ? newValue - oldValue : oldValue - newValue;

  return (
    <Badge>
      <Flex align="center" gap="4px">
        <Flex align="center">
          <LabelMini
            style={{ fontWeight: 700 }}
            color={
              !showTrendIcon || !diff
                ? theme.iconSecondary
                : isTrendPositive
                  ? theme.greenPrimary
                  : theme.redPrimary
            }
          >
            {!diff ? "" : isTrendPositive ? "+" : "-"}
            {diff}
          </LabelMini>
          {showTrendIcon && !!diff && (
            <Icon
              name={isTrendPositive ? "trendUp" : "trendDown"}
              size={16}
              color={isTrendPositive ? theme.greenPrimary : theme.redPrimary}
            />
          )}
        </Flex>
        <LabelMini style={{ width: "70px" }}>{timeframeLabel}</LabelMini>
      </Flex>
    </Badge>
  );
};
