import { ScanSummaryPage } from "../types/ScanSummary";

export const mockScanSummaries: ScanSummaryPage = {
  results: [
    {
      total_findings: 1,
      skipped_nessus_info_findings: 0,
      skipped_ignored_findings: 0,
      skipped_reported_findings: 0,
      merged_findings: 0,
      total_reported_findings: 1,
      findings_risks_counter: {
        low: 0,
        medium: 0,
        high: 1,
        critical: 0,
        informative: 0,
      },
      scanner: "nessus",
      scan_id: "dbe9c4d4-769c-4936-adc0-3e88fdf1bf54",
      scan_start_time: "2025-01-29T19:16:05.195000",
      scan_end_time: "2025-01-29T19:16:23.677000",
      customer_id: 1,
      assets: [],
    },
    {
      total_findings: 35,
      skipped_nessus_info_findings: 0,
      skipped_ignored_findings: 8,
      skipped_reported_findings: 15,
      merged_findings: 4,
      total_reported_findings: 8,
      findings_risks_counter: {
        low: 27,
        medium: 2,
        high: 3,
        critical: 0,
        informative: 3,
      },
      scanner: "nuclei",
      scan_id: "b4806983-d601-497d-a786-06b649a223c7",
      scan_start_time: "2024-12-21T04:01:55.094000",
      scan_end_time: "2024-12-22T12:26:56.574000",
      customer_id: 1,
      assets: [],
    },
    {
      total_findings: 12,
      skipped_nessus_info_findings: 0,
      skipped_ignored_findings: 0,
      skipped_reported_findings: 11,
      merged_findings: 0,
      total_reported_findings: 1,
      findings_risks_counter: {
        low: 1,
        medium: 8,
        high: 3,
        critical: 0,
        informative: 0,
      },
      scanner: "retire",
      scan_id: "7e521705-dad6-4021-af31-a60a1b89cf33",
      scan_start_time: "2024-12-01T04:38:20.369000",
      scan_end_time: "2024-12-01T05:40:15.166000",
      customer_id: 1,
      assets: [],
    },
    {
      total_findings: 12,
      skipped_nessus_info_findings: 0,
      skipped_ignored_findings: 0,
      skipped_reported_findings: 10,
      merged_findings: 0,
      total_reported_findings: 2,
      findings_risks_counter: {
        low: 1,
        medium: 6,
        high: 5,
        critical: 0,
        informative: 0,
      },
      scanner: "retire",
      scan_id: "6e6a0c2c-d4aa-475d-969c-8e6272808837",
      scan_start_time: "2024-11-18T03:43:53.581000",
      scan_end_time: "2024-11-18T05:19:31.879000",
      customer_id: 1,
      assets: [],
    },
    {
      total_findings: 12,
      skipped_nessus_info_findings: 0,
      skipped_ignored_findings: 0,
      skipped_reported_findings: 11,
      merged_findings: 0,
      total_reported_findings: 1,
      findings_risks_counter: {
        low: 1,
        medium: 8,
        high: 3,
        critical: 0,
        informative: 0,
      },
      scanner: "leakage",
      scan_id: "7e521705-dad6-4021-af31-a60a1b89cf33",
      scan_start_time: "2024-10-01T04:38:20.369000",
      scan_end_time: "2024-10-01T05:40:15.166000",
      customer_id: 1,
      assets: [],
    },
    {
      total_findings: 12,
      skipped_nessus_info_findings: 0,
      skipped_ignored_findings: 0,
      skipped_reported_findings: 11,
      merged_findings: 0,
      total_reported_findings: 1,
      findings_risks_counter: {
        low: 1,
        medium: 8,
        high: 3,
        critical: 0,
        informative: 0,
      },
      scanner: "quick",
      scan_id: "7e521705-dad6-4021-af31-a60a1b89cf33",
      scan_start_time: "2024-9-01T04:38:20.369000",
      scan_end_time: "2024-9-01T05:40:15.166000",
      customer_id: 1,
      assets: [],
    },
  ],
  count: 1,
  next: null,
  previous: null,
};
