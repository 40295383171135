import { SeparatorVertical } from "../../../components/elements/separators/SeparatorVertical";
import { HeaderSecondary } from "../../../components/elements/typography/Typography";
import { FindingsCounts } from "../../../types/Finding";
import {
  neglectedFindingTooltipContent,
  slaFindingTooltipContent,
  unassignedFindingTooltipContent,
  urgentFindingTooltipContent,
  widespreadFindingTooltipContent,
} from "../../../shared/tooltipTexts";
import { TopBox, TopBoxTitle } from "../FindingsStyles";
import { Flex } from "../../../components/layouts/flex/Flex";
import { objectToBase64 } from "../../../shared/helper";
import { findingsStartingFilters } from "../Findings";
import { ActionItem } from "./ActionItem";
import { HorizontalScroller } from "../../../components/elements/horizontalScroller/HorizontalScroller";

const ninetyDaysAgo = new Date();
ninetyDaysAgo.setDate(ninetyDaysAgo.getDate() - 90);

const ActionItems = ({
  findingsCounts,
  isFetching,
  onClickCallback,
}: {
  findingsCounts: FindingsCounts | undefined;
  isFetching?: boolean;
  onClickCallback?: () => void;
}) => {
  return (
    <TopBox style={{ gap: "16px", width: "100%" }}>
      <TopBoxTitle>
        <HeaderSecondary
          className="text-truncate"
          style={{ width: "calc(100% - 32px)" }}
        >
          Action Items
        </HeaderSecondary>
      </TopBoxTitle>

      <HorizontalScroller>
        <Flex justify="between" align="center" gap="16px" w100>
          <ActionItem
            title="Urgent"
            dataTestId="urgent-counter"
            countsKey="urgent"
            tooltipContent={urgentFindingTooltipContent}
            onClick={() => onClickCallback && onClickCallback()}
            linkTo={`/findings?filters=${objectToBase64({
              ...findingsStartingFilters,
              urgent: true,
            })}`}
            isFetching={!!isFetching}
            findingsCounts={findingsCounts}
          />
          <SeparatorVertical style={{ height: "90px" }} />
          <ActionItem
            title="Unassigned"
            dataTestId="unassigned-counter"
            countsKey="unassigned"
            tooltipContent={unassignedFindingTooltipContent}
            onClick={() => onClickCallback && onClickCallback()}
            linkTo={`/findings?filters=${objectToBase64({
              ...findingsStartingFilters,
              assignee: "unassigned",
            })}`}
            isFetching={!!isFetching}
            findingsCounts={findingsCounts}
          />
          <SeparatorVertical style={{ height: "90px" }} />
          <ActionItem
            title="Neglected"
            dataTestId="neglected-counter"
            countsKey="neglected"
            tooltipContent={neglectedFindingTooltipContent}
            onClick={() => onClickCallback && onClickCallback()}
            linkTo={`/findings?filters=${objectToBase64({
              ...findingsStartingFilters,
              updated_at_before: ninetyDaysAgo,
            })}`}
            isFetching={!!isFetching}
            findingsCounts={findingsCounts}
          />
          <SeparatorVertical style={{ height: "90px" }} />
          <ActionItem
            title="SLA Breaches"
            dataTestId="sla-counter"
            countsKey="breached_sla"
            tooltipContent={slaFindingTooltipContent}
            onClick={() => onClickCallback && onClickCallback()}
            linkTo={`/findings?filters=${objectToBase64({
              ...findingsStartingFilters,
              breached_sla: true,
            })}`}
            isFetching={!!isFetching}
            findingsCounts={findingsCounts}
          />
          <SeparatorVertical style={{ height: "90px" }} />
          <ActionItem
            title="Widespread"
            dataTestId="widespread-counter"
            countsKey="widespread"
            tooltipContent={widespreadFindingTooltipContent}
            onClick={() => onClickCallback && onClickCallback()}
            linkTo={`/findings?filters=${objectToBase64({
              ...findingsStartingFilters,
              widespread: true,
            })}`}
            isFetching={!!isFetching}
            findingsCounts={findingsCounts}
          />
        </Flex>
      </HorizontalScroller>
    </TopBox>
  );
};

export default ActionItems;
