export const cvssTooltip = `CVSS Score is a contextual risk calculation
modified by environmental parameters such as asset impact and exploitability
to reflect actual risk caused bu the weakness / vulnerability detected`;

export const urgentFindingTooltipContent =
  "Findings with critical severity or high/critical severity that have breached the SLA.";

export const unassignedFindingTooltipContent =
  "Findings without an assigned user to resolve the issue.";

export const neglectedFindingTooltipContent =
  "Unresolved findings that have not been updated in the past 90 days.";

export const slaFindingTooltipContent =
  "Findings that have exceeded the SLA-defined resolution time.";

export const widespreadFindingTooltipContent =
  "Findings that affect multiple assets.";

export const UnprotectedTooltipContent =
  "Unprotected Assets are those that our automated Application Security Management (ASM) tool doesn't currently monitor for vulnerabilities.";
