import { useContext, useState } from "react";
import { ThemeContext } from "styled-components";
import { Box } from "../../../components/elements/box/Box";
import {
  Dropdown,
  Option,
} from "../../../components/elements/dropdowns/Dropdown";
import { TrendUpGreenIcon } from "../../../components/elements/icon/TrendUpGreenIcon";
import { SkeletonLoading } from "../../../components/elements/loading/SkeletonLoading";
import { SeparatorVertical } from "../../../components/elements/separators/SeparatorVertical";
import {
  HeaderSubBold,
  KPISmall,
  LabelRegular,
  LabelRegularHover,
} from "../../../components/elements/typography/Typography";
import { useApiAssetsCount } from "../../../hooks/queries/assetsContext";
import { useScreenWidth } from "../../../hooks/utilsHooks";
import { SCREEN_LAPTOP_WIDTH } from "../../../shared/consts";
import { Filter } from "../../../types/AssetsView";
import { Flex } from "../../../components/layouts/flex/Flex";
import { Mixpanel } from "../../../shared/mixpanel";
import { useSearchParams } from "react-router-dom";
import {
  convertDateToDaysAgo,
  fromBase64AssetsView,
  getDate,
  getDateTimeDaysBeforeNow,
  toBase64AssetsView,
} from "../../../shared/helper";
import { emptyAssetsViewProps } from "../filters/FiltersUtils";
import { Tooltip } from "../../../components/elements/tooltip/Tooltip";
import { AssetsRiskPieChart } from "./AssetsRiskPieChart";

type Props = {
  filters: Filter[];
};

export const Overview = (props: Props) => {
  const { filters } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useContext(ThemeContext);
  const screenWidth = useScreenWidth();
  const isLaptop = screenWidth < SCREEN_LAPTOP_WIDTH;

  const createdAtFilter = filters.find(
    (f) => f.column === "created_at" && f.condition === "after"
  );

  // Fixing issue when redirected to assets page with created after filter
  let daysAgo =
    createdAtFilter && convertDateToDaysAgo(new Date(createdAtFilter.value));
  if (daysAgo && daysAgo > 31 && daysAgo < 365) {
    daysAgo = 180;
  }
  const [timeframe, setTimeframe] = useState<number>(daysAgo || 1);

  const { data: assetsCounts, isFetching } = useApiAssetsCount(
    {
      timeframe: timeframe,
    },
    filters
  );

  const timeOptions: Option[] = [
    { label: "Last 24 Hours", value: "1" },
    { label: "Last 7 Days", value: "7" },
    { label: "Last 30 Days", value: "30" },
    { label: "Last 6 Months", value: "180" },
  ];

  const newlyDiscoverd = assetsCounts?.newly_discoverd || 0;

  const setFilteredView = () => {
    Mixpanel.track("Filter assets timeframe", { timeframe });
    let viewBase64 = searchParams.get("view") || "";
    let view = fromBase64AssetsView(viewBase64) || emptyAssetsViewProps;
    let viewFilters = view.filters.filter((f) => f.column !== "created_at");
    viewFilters.push({
      value: getDateTimeDaysBeforeNow(timeframe).toISOString(),
      column: "created_at",
      condition: "after",
      order: 0,
      next_condition: "and",
    });
    view.filters = viewFilters;
    viewBase64 = toBase64AssetsView(view);
    setSearchParams({ ...searchParams, view: viewBase64 });
  };

  return (
    <Box
      style={{
        padding: "16px",
        paddingBottom: isLaptop ? "16px" : "24px",
        height: "100% ",
      }}
    >
      <Flex column justify="between" gap="6px" h100>
        <Flex align="center" gap="8px">
          <HeaderSubBold>Assets Overview</HeaderSubBold>
        </Flex>
        <Flex column align="start" gap="8px">
          <Flex align="center" gap="16px">
            <Flex
              column
              gap="8px"
              justify="center"
              style={{ paddingRight: "4px", width: "290px" }}
            >
              <AssetsRiskPieChart filters={filters} />
            </Flex>
            <SeparatorVertical />
            <Flex
              column
              align="start"
              justify="center"
              gap="8px"
              style={{ width: "140px" }}
            >
              <Flex align="center" gap="16px">
                {isFetching && !assetsCounts?.total ? (
                  <SkeletonLoading width="24px" height="24px" />
                ) : (
                  <KPISmall
                    style={{
                      color:
                        (assetsCounts?.newly_discoverd || 0) > 0
                          ? theme.greenPrimary
                          : theme.textSub,
                      lineHeight: isLaptop ? "24px" : "",
                      paddingLeft: "8px",
                    }}
                  >
                    {newlyDiscoverd}
                  </KPISmall>
                )}

                {newlyDiscoverd > 0 && <TrendUpGreenIcon />}
              </Flex>
              <Flex column>
                <span
                  style={{
                    color: theme.black700,
                    lineHeight: isLaptop ? "16px" : "",
                    paddingLeft: "8px",
                  }}
                >
                  {newlyDiscoverd > 0 ? (
                    <Tooltip
                      content={`Filter assets created after ${getDate(getDateTimeDaysBeforeNow(timeframe))}`}
                    >
                      <LabelRegularHover onClick={setFilteredView}>
                        Assets Found In The
                      </LabelRegularHover>
                    </Tooltip>
                  ) : (
                    <LabelRegular>Assets Found In The</LabelRegular>
                  )}
                </span>
                <Flex>
                  <Dropdown
                    onChange={(option) => {
                      Mixpanel.track("Changed timeframe", {
                        Selected: option?.label,
                      });
                      setTimeframe(parseInt(option?.value.toString() || "1"));
                    }}
                    options={timeOptions}
                    value={timeOptions.find(
                      (option) =>
                        option.value.toString() === timeframe.toString()
                    )}
                  />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};
