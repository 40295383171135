import { useContext, useRef, useState } from "react";
import { ThemeContext } from "styled-components";
import {
  useApiAssetsBreakdown,
  useApiAssetsRiskDistribution,
} from "../../../hooks/queries/assetsContext";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ScaleChartOptions,
  DatasetChartOptions,
  PluginChartOptions,
  ElementChartOptions,
  CoreChartOptions,
  BarControllerChartOptions,
  ChartEvent,
} from "chart.js";
import { HeaderSecondary } from "../../../components/elements/typography/Typography";
import { Icon } from "../../../components/elements/icon/Icon";
import {
  Dropdown,
  Option,
} from "../../../components/elements/dropdowns/Dropdown";
import {
  assetsDistributionOptions,
  defaultAssetDistribution,
} from "../../../shared/consts";
import { Flex } from "../../../components/layouts/flex/Flex";
import { SingleValue } from "react-select";
import { NoDataAvailable } from "../NoDataAvailable";
import { _DeepPartialObject } from "chart.js/dist/types/utils";
import { getTooltip } from "../../../components/composed/charts/externalTooltip";
import { SeparatorVertical } from "../../../components/elements/separators/SeparatorVertical";
import { Mixpanel } from "../../../shared/mixpanel";
import { toBase64AssetsView } from "../../../shared/helper";
import { useNavigate } from "react-router";
import { useApiProducts } from "../../../hooks/queries/productsContext";
import {
  getGradeFilters,
  gradeToRiskLevelMap,
  priorityOptions,
} from "../../assets/AssetUtils";
import { AssetGrade } from "../../../types/Asset";
import { useSearchParams } from "react-router-dom";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.defaults.font.family = "poppins";
ChartJS.defaults.font.size = 11;
ChartJS.defaults.font.lineHeight = "21px";
// ChartJS.defaults.font.weight = "700";

const sortOptions = [
  { value: "desc", label: "High to Low" },
  { value: "asc", label: "Low to High" },
];

type BarChartOptions = _DeepPartialObject<
  CoreChartOptions<"bar"> &
    ElementChartOptions<"bar"> &
    PluginChartOptions<"bar"> &
    DatasetChartOptions<"bar"> &
    ScaleChartOptions<"bar"> &
    BarControllerChartOptions
>;

export const AssetsBarChart = () => {
  const theme = useContext(ThemeContext);
  const chartRef = useRef<ChartJS<"bar">>(null);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const distributionParam = searchParams.get("distribution");

  const [distribution, setDistribution] = useState<SingleValue<Option>>(
    !!distributionParam
      ? (assetsDistributionOptions.find(
          (opt) => opt.value === distributionParam
        ) as SingleValue<Option>)
      : defaultAssetDistribution
  );

  const [selectedSorting, setSelectedSorting] = useState<SingleValue<Option>>(
    sortOptions[0]
  );

  const { data: products } = useApiProducts();

  const { data: assetsRisks } = useApiAssetsRiskDistribution(
    [], // filters,
    // query should be enabled only if distribution is security grade
    `${distribution?.value}` === "risk_score"
  );

  const { data: assetsBreakdown, isFetching } = useApiAssetsBreakdown(
    distribution?.value.toString() || defaultAssetDistribution.value,
    `${selectedSorting?.value}`,
    [],
    `${distribution?.value}` !== "risk_score" // disable if risk score
  );

  const breakdown =
    `${distribution?.value}` === "risk_score" ? assetsRisks : assetsBreakdown;

  const getLabel = (key: string) => {
    const distributionLabelsMap: { [key: string]: string } = {
      risk_score: `${key.toUpperCase()} - ${gradeToRiskLevelMap[key]}`,
      product_id: products?.find((p) => p.id === parseInt(key))?.name || "",
      priority:
        priorityOptions.find((p) => p.value === parseInt(key))?.label || "",
      type: key.replace("_", " "),
    };
    return distributionLabelsMap[`${distribution?.value}`] || key;
  };

  const dataSet =
    breakdown &&
    Object.keys(breakdown).map((key, i) => ({
      label: getLabel(key),
      value: Object.values(breakdown)[i],
    }));

  const barChartOptions: BarChartOptions = {
    responsive: true,
    // maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: false,
        mode: "point",
        external: (context: any) =>
          getTooltip(context, theme, "bar", 0, "Click to view on assets page"),
        usePointStyle: true,
      },
    },
    indexAxis: "x",
    elements: {
      bar: {
        // borderColor: "transparent",
        borderRadius: 25,
        borderSkipped: false,
        borderWidth: 1,
        inflateAmount: 0,
      },
    },
    scales: {
      x: {
        ticks: {
          color: theme.textSecondary,
        },
        grid: { color: theme.separation },
      },
      y: {
        grid: { color: theme.separation },
        ticks: { stepSize: 1 },
      },
    },
    onClick: (e: ChartEvent) => {
      const canvas = e.native?.target as HTMLCanvasElement;
      const chart = ChartJS.getChart(canvas); // Get the chart instance
      if (!chart) return;
      const elements = chart.getElementsAtEventForMode(
        e as unknown as Event, // Casting to Event for compatibility
        "nearest",
        { intersect: true },
        false
      );
      if (elements.length > 0) {
        const firstElement = elements[0];
        const labelIndex = firstElement.index;
        const label = chart.data.labels![labelIndex];
        const column =
          `${distribution?.value}` === "product_id"
            ? "product"
            : `${distribution?.value}`;

        const view = toBase64AssetsView({
          name: "",
          filters:
            column === "risk_score"
              ? getGradeFilters(`${label}`.substring(0, 1) as AssetGrade)
              : [
                  {
                    column,
                    value:
                      column === "product"
                        ? `${products?.find((p) => p.name === label)?.id}`
                        : column === "priority"
                          ? `${priorityOptions.find((p) => p.label === label)?.value}`
                          : column === "type"
                            ? `${label}`.replace(" ", "_")
                            : `${label}`,
                    condition: column === "technologies" ? "contains" : "is",
                    order: 0,
                    next_condition: "and",
                  },
                ],
        });

        navigate(`/assets?view=${view}`);
      }
    },
  };

  const chartData = {
    labels: dataSet?.map((d) => d.label),
    datasets: [
      {
        label: "",
        data: dataSet?.map(() => 0),
        backgroundColor: "transparent",
      },
      {
        label: "Assets",
        data: dataSet?.map((d) => d.value),
        backgroundColor: theme.blue500,
      },
      {
        label: "",
        data: dataSet?.map(() => 0),
        backgroundColor: "transparent",
      },
    ],
  };

  return (
    <Flex w100 column gap="12px">
      <Flex w100 align="center" gap="8px">
        <HeaderSecondary>Distribution of Assets by</HeaderSecondary>
        <SeparatorVertical height="16px" />

        <div>
          <Dropdown
            onChange={(option) => {
              Mixpanel.track("Distribution of Assets selected", {
                selected: option?.label,
              });
              setDistribution(option);
              searchParams.set("distribution", `${option?.value}`);
              setSearchParams(searchParams);
            }}
            value={distribution}
            options={assetsDistributionOptions}
            isBold
          />
        </div>

        <Flex align="center" className="ms-auto">
          <Icon name="sort" color={theme.primary} />
          <Dropdown
            onChange={(option) => {
              Mixpanel.track("Sorting changed", { selected: option?.label });
              setSelectedSorting(option);
            }}
            value={selectedSorting}
            options={sortOptions}
          />
        </Flex>
      </Flex>

      <Bar
        height={50}
        options={barChartOptions}
        data={chartData}
        ref={chartRef}
      />
      {!isFetching && !Object.keys(breakdown || {}).length && (
        <NoDataAvailable />
      )}
    </Flex>
  );
};
