import { Dispatch, SetStateAction, useContext } from "react";
import { TagsLine } from "../../../../components/composed/tagsLine/TagsLine";
import { FormError } from "../../../../components/elements/FormsElements/FormError";
import { Box } from "../../../../components/elements/box/Box";
import { Dropdown } from "../../../../components/elements/dropdowns/Dropdown";
import { Icon } from "../../../../components/elements/icon/Icon";
import {
  RequiredField,
  OptionalField,
} from "../../../../components/elements/requiredField/RequiredField";
import {
  HeaderSecondary,
  LabelRegular,
} from "../../../../components/elements/typography/Typography";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { StatusMapping } from "../../../../shared/consts";
import {
  objectsToOptions,
  getOptionFromObjects,
  getOptionFromKeyValuePairs,
  FormModeState,
  FormErrors,
} from "../../../../shared/formUtils";
import {
  AdminFindingEdit,
  Finding,
  FindingStatus,
  JiraUser,
} from "../../../../types/Finding";
import { SuperUsersDropdown } from "../../../../components/composed/dropdowns/SuperUsersDropdown";
import { ThemeContext } from "styled-components";
import { Customer } from "../../../../types/Customer";
import { Project } from "../../../../types/Project";
import { OpUser } from "../../../../hooks/queries/usersContext";
import { Option } from "../../../../components/elements/dropdowns/Dropdown";
import { useParams } from "react-router";
import { useUpdateFindingInPlace } from "../../../../shared/formUtils";
import { useApiMe } from "../../../../hooks/queries/meContext";

type Props = {
  formMode: FormModeState;
  formErrors: FormErrors;
  setFormErrors: (formErrors: FormErrors) => void;
  createFindingData: AdminFindingEdit;
  setCreateFinding: Dispatch<SetStateAction<AdminFindingEdit>>;
  updateFindingData?: Finding;
  setUpdateFinding: Dispatch<SetStateAction<Finding | undefined>>;
  editableUpdateFindingData: AdminFindingEdit | null;
  setEditableUpdateFinding: Dispatch<SetStateAction<AdminFindingEdit | null>>;
  customers: Customer[] | undefined;
  projects: Project[] | undefined;
};

export const ProjectDetails = (props: Props) => {
  const {
    formMode,
    formErrors,
    createFindingData,
    setCreateFinding: setCreateFindingData,
    setUpdateFinding,
    editableUpdateFindingData,
    setEditableUpdateFinding,
    customers,
    projects,
    setFormErrors,
  } = props;

  const theme = useContext(ThemeContext);
  const { data: me } = useApiMe();
  const { id: updateFindingId } = useParams();

  const { updateInPlace, queryStatus, changedField } = useUpdateFindingInPlace(
    parseInt(updateFindingId ? updateFindingId : "0"),
    setUpdateFinding
  );

  const onAssigneeChangedHandler = (opUser: OpUser) => {
    var jiraUser: JiraUser = {
      jira_id: "",
      display_name: opUser.first_name + " " + opUser.last_name,
      email: opUser.email,
      avatar_url: opUser.avatar_url,
    };
    if (formMode === FormModeState.Update) {
      setEditableUpdateFinding((prev) => ({
        ...prev,
        op_jira_assignee: jiraUser,
      }));
      updateInPlace({ op_jira_assignee: jiraUser });
    }

    if (formMode === FormModeState.Create) {
      setCreateFindingData((prev) => ({
        ...prev,
        op_jira_assignee: jiraUser,
      }));
    }

    let errors = { ...formErrors };
    errors.op_jira_assignee = [];
    setFormErrors(errors);
  };

  const getProjectValue = (): Option | null => {
    var currentProjectIdSelected =
      formMode === FormModeState.Update
        ? editableUpdateFindingData?.project
        : createFindingData.project;
    var currentCustomer =
      formMode === FormModeState.Update
        ? editableUpdateFindingData?.customer
        : createFindingData.customer;

    var currentProjectOptions = projects
      ? objectsToOptions(
          projects?.filter((p) => p.customer === currentCustomer) || []
        )
      : [];

    // Checking to avoid edge case where selected project is not owned by selected customer
    for (const currentProj of currentProjectOptions) {
      if (Number(currentProj.value) === Number(currentProjectIdSelected)) {
        return currentProj;
      }
    }

    return { label: "Select an option", value: 0 };
  };

  return (
    <Box style={{ width: "50%" }}>
      <Flex column gap="32px">
        <Flex gap="8px">
          <Icon name="projects" size={30} color={theme.primary} />{" "}
          <HeaderSecondary>Project Details</HeaderSecondary>
        </Flex>
        <Flex column gap="8px">
          <Flex>
            <LabelRegular>Customer</LabelRegular>
            <RequiredField />
          </Flex>
          <Dropdown
            searchable
            placeholder="Select customer"
            variant="border"
            size="medium"
            options={customers ? objectsToOptions(customers) : []}
            disabled={
              formMode === FormModeState.Update ||
              (me?.customer.is_multi_tenant &&
                !me.is_superuser &&
                formMode === FormModeState.Create)
            }
            value={
              formMode === FormModeState.Update
                ? editableUpdateFindingData?.customer && customers
                  ? getOptionFromObjects(
                      customers,
                      editableUpdateFindingData?.customer
                    )
                  : null
                : createFindingData.customer && customers
                  ? getOptionFromObjects(customers, createFindingData.customer)
                  : null
            }
            onChange={(opt) => {
              if (opt?.value) {
                if (formMode === FormModeState.Update)
                  setEditableUpdateFinding((prev) => ({
                    ...prev,
                    customer: Number(opt.value),
                    affected_assets: [],
                    affected_assets_displayed: [],
                  }));
                if (formMode === FormModeState.Create)
                  setCreateFindingData((prev) => ({
                    ...prev,
                    customer: Number(opt.value),
                    affected_assets: [],
                    affected_assets_displayed: [],
                  }));

                let errors = { ...formErrors };
                errors.customer = [];
                setFormErrors(errors);
              }
            }}
            isError={!!formErrors?.customer?.length}
          />
          {formErrors?.customer?.map((err) => <FormError errorMessage={err} />)}
        </Flex>
        <Flex column gap="8px">
          <Flex>
            <LabelRegular>Project</LabelRegular>
            <RequiredField />
          </Flex>
          <Dropdown
            searchable
            placeholder="Select project"
            variant="border"
            size="medium"
            disabled={
              formMode === FormModeState.Update
                ? !editableUpdateFindingData?.customer || queryStatus !== "idle"
                : !createFindingData.customer
            }
            options={
              projects && formMode === FormModeState.Update
                ? objectsToOptions(
                    projects?.filter(
                      (p) => p.customer === editableUpdateFindingData?.customer
                    ) || []
                  )
                : projects && formMode === FormModeState.Create
                  ? objectsToOptions(
                      projects?.filter(
                        (p) => p.customer === createFindingData?.customer
                      ) || []
                    )
                  : []
            }
            value={getProjectValue()}
            queryStatus={changedField === "project" ? queryStatus : undefined}
            onChange={(opt) => {
              if (opt?.value) {
                if (formMode === FormModeState.Update) {
                  setEditableUpdateFinding((prev) => ({
                    ...prev,
                    project: Number(opt.value),
                  }));
                  updateInPlace({ project: Number(opt.value) });
                }
                if (formMode === FormModeState.Create)
                  setCreateFindingData((prev) => ({
                    ...prev,
                    project: Number(opt.value),
                  }));

                let errors = { ...formErrors };
                errors.project = [];
                setFormErrors(errors);
              }
            }}
            isError={!!formErrors?.project?.length}
          />
          {formErrors?.project?.map((err) => <FormError errorMessage={err} />)}
        </Flex>
        <Flex column gap="8px">
          <Flex justify="between">
            <Flex>
              <LabelRegular>Status</LabelRegular>
              <RequiredField />
            </Flex>
          </Flex>
          <Dropdown
            searchable
            placeholder="Select a Status"
            variant="border"
            size="medium"
            disabled={queryStatus !== "idle"}
            queryStatus={changedField === "status" ? queryStatus : undefined}
            value={
              formMode === FormModeState.Update
                ? editableUpdateFindingData?.status
                  ? getOptionFromKeyValuePairs(
                      StatusMapping,
                      editableUpdateFindingData?.status
                    )
                  : null
                : createFindingData?.status
                  ? getOptionFromKeyValuePairs(
                      StatusMapping,
                      createFindingData?.status
                    )
                  : null
            }
            options={Object.keys(StatusMapping).map((key) => ({
              value: key,
              label: StatusMapping[key as keyof typeof StatusMapping],
            }))}
            onChange={(opt) => {
              if (opt?.value) {
                if (formMode === FormModeState.Update) {
                  setEditableUpdateFinding((prev) => ({
                    ...prev,
                    status: opt.value as FindingStatus,
                  }));
                  updateInPlace({ status: opt.value as FindingStatus });
                }
                if (formMode === FormModeState.Create)
                  setCreateFindingData((prev) => ({
                    ...prev,
                    status: opt.value as FindingStatus,
                  }));
              }
            }}
          />
        </Flex>
        {(me?.customer.is_multi_tenant === false || me?.is_superuser) && (
          <Flex column gap="8px">
            <Flex>
              <LabelRegular>OP User Assignee</LabelRegular>
              <RequiredField />
            </Flex>
            <SuperUsersDropdown
              placeholder="Select an option"
              valueByEmail={
                formMode === FormModeState.Update
                  ? editableUpdateFindingData?.op_jira_assignee?.email
                  : createFindingData.op_jira_assignee?.email
              }
              size="medium"
              onSelect={(opUser) => opUser && onAssigneeChangedHandler(opUser)}
              queryStatus={
                changedField === "op_jira_assignee" ? queryStatus : undefined
              }
              disabled={queryStatus !== "idle"}
              isError={!!formErrors?.op_jira_assignee?.length}
            />
            {formErrors?.op_jira_assignee?.map((err) => (
              <FormError errorMessage={err} />
            ))}
          </Flex>
        )}
        <Flex column gap="8px">
          <Flex justify="between">
            <Flex>
              <LabelRegular>Labels</LabelRegular>
              <OptionalField />
            </Flex>
          </Flex>
          <TagsLine
            isEditable={queryStatus === "idle"}
            selectedTags={
              formMode === FormModeState.Update
                ? editableUpdateFindingData?.labels
                  ? editableUpdateFindingData?.labels
                  : []
                : createFindingData?.labels
                  ? createFindingData?.labels
                  : []
            }
            onTagAdd={(tagLabel: string) => {
              if (formMode === FormModeState.Update) {
                setEditableUpdateFinding((prev) => ({
                  ...prev,
                  labels: editableUpdateFindingData?.labels
                    ? [...editableUpdateFindingData?.labels, tagLabel]
                    : [tagLabel],
                }));
                updateInPlace({
                  labels: editableUpdateFindingData?.labels
                    ? [...editableUpdateFindingData?.labels, tagLabel]
                    : [tagLabel],
                });
              }
              if (formMode === FormModeState.Create)
                setCreateFindingData((prev) => ({
                  ...prev,
                  labels: createFindingData?.labels
                    ? [...createFindingData?.labels, tagLabel]
                    : [tagLabel],
                }));
            }}
            onTagRemove={(tagLabel: string) => {
              if (formMode === FormModeState.Update) {
                setEditableUpdateFinding((prev) => ({
                  ...prev,
                  labels: editableUpdateFindingData?.labels
                    ? editableUpdateFindingData?.labels.filter(
                        (label) => label !== tagLabel
                      )
                    : [],
                }));
                updateInPlace({
                  labels: editableUpdateFindingData?.labels
                    ? editableUpdateFindingData?.labels.filter(
                        (label) => label !== tagLabel
                      )
                    : [],
                });
              }
              if (formMode === FormModeState.Create) {
                setCreateFindingData((prev) => ({
                  ...prev,
                  labels: createFindingData?.labels
                    ? createFindingData?.labels.filter(
                        (label) => label !== tagLabel
                      )
                    : [],
                }));
              }
            }}
          />
        </Flex>
      </Flex>
    </Box>
  );
};
