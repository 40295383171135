import { useContext, useState } from "react";
import { Box } from "../../../components/elements/box/Box";
import { HeaderSecondary } from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { ThemeContext } from "styled-components";
import { SingleValue } from "react-select";
import {
  Dropdown,
  Option,
} from "../../../components/elements/dropdowns/Dropdown";
import {
  assetsDistributionConditionsMap,
  assetsDistributionOptions,
} from "../../../shared/consts";
import { useApiProducts } from "../../../hooks/queries/productsContext";
import {
  useApiAssetsBreakdown,
  useApiAssetsCount,
} from "../../../hooks/queries/assetsContext";
import { DistributionChartItemData } from "../../assets/topBoxes/TopDistributionItem";
import {
  getGradeFilters,
  gradeToRiskLevelMap,
  priorityOptions,
} from "../../assets/AssetUtils";
import { AssetGrade } from "../../../types/Asset";
import { Mixpanel } from "../../../shared/mixpanel";
import { DoughnutChart } from "../../../components/composed/charts/DoughnutChart";
import { LegendItem } from "../../../components/elements/legend/LegendItem";
import { useNavigate } from "react-router";
import { toBase64AssetsView } from "../../../shared/helper";
import { useRiskColors } from "../../../shared/findingsHelper";
import { TextButton } from "../../../components/elements/button/text/TextButton";
import { AssetsRiskPieChart } from "../../assets/topBoxes/AssetsRiskPieChart";
import { Filter } from "../../../types/AssetsView";

const defaultAssetDistribution = {
  label: "Security Grade",
  value: "risk_score",
};

type DataItem = {
  percentage: number;
  name: string;
  value: number;
};

type Props = {
  selectedProduct: number | "all";
};

export const DistributionOfAssets = (props: Props) => {
  const { selectedProduct } = props;
  const navigate = useNavigate();
  const theme = useContext(ThemeContext);
  const { riskColors } = useRiskColors({ withInfo: true });

  const [distribution, setDistribution] = useState<SingleValue<Option>>(
    defaultAssetDistribution
  );

  const distributionStr = (
    distribution || defaultAssetDistribution
  ).value.toString();

  const { data: products } = useApiProducts();

  const assetsFilters: Filter[] =
    !!selectedProduct && selectedProduct !== "all"
      ? [
          {
            column: "product",
            value: `${selectedProduct}`,
            condition: "is",
            order: 0,
            next_condition: "and",
          },
        ]
      : [];

  const { data: assetsCounts } = useApiAssetsCount({}, assetsFilters);

  const { data: breakdown, isFetching } = useApiAssetsBreakdown(
    distributionStr,
    "desc",
    assetsFilters,
    // query should be disabled if distribution is security grade
    distributionStr !== "risk_score"
  );

  const processData = (data: {
    [key: string]: number;
  }): DistributionChartItemData[] =>
    Object.keys(data)
      .filter((key) => !!key)
      .map((key) => ({ name: key, value: data[key], percentage: 0 }))
      .sort((a, b) => {
        if (["priority", "risk_score"].includes(distributionStr))
          return a.name > b.name ? -1 : 1;
        return b.value - a.value;
      });

  let processedData = processData(breakdown || {});
  const isBreakdownDataLongerThanDisplayed =
    !!processedData?.length && processedData.length > 5;
  // Limit to 5 items
  processedData = processedData.slice(0, 5);

  const totalData = processedData.reduce((acc, curr) => acc + curr.value, 0);
  const dataWithPercentage: DataItem[] = processedData.map((item) => ({
    ...item,
    percentage: (item.value / totalData) * 100,
  }));

  const getLabel = (distributeBy: string, itemName: string): string => {
    const labelsMap: { [key: string]: string } = {
      product_id:
        products?.find((p) => p.id === parseInt(itemName))?.name || "",
      priority:
        priorityOptions.find((p) => p.value === parseInt(itemName))?.label ||
        "",
      risk_score: gradeToRiskLevelMap[itemName],
      type: itemName.replace("_", " "),
    };

    return Object.keys(labelsMap).includes(distributeBy)
      ? labelsMap[distributeBy]
      : itemName;
  };

  const getFilterKey = (distributeBy: string): string => {
    const filtersMap: { [key: string]: string } = {
      product_id: "product",
    };
    return Object.keys(filtersMap).includes(distributeBy)
      ? filtersMap[distributeBy]
      : distributeBy;
  };

  const getFilter = (selectedGroupName: string, distributeBy: string) =>
    distributeBy === "risk_score"
      ? getGradeFilters(selectedGroupName as AssetGrade)
      : [
          {
            column: getFilterKey(distributeBy),
            value: selectedGroupName,
            condition: assetsDistributionConditionsMap[distributeBy],
            order: 0,
            next_condition: "and",
          },
        ];

  const onChangeGroupBy = (selectedDistribution: SingleValue<Option>) => {
    Mixpanel.track("Dashboard - Distribution of Assets - change dist", {
      selected: selectedDistribution?.label,
    });
    setDistribution(selectedDistribution);
  };

  const labels = dataWithPercentage.map((dataItem, idx) =>
    getLabel(distributionStr, dataItem.name)
  );

  const colors =
    distributionStr === "risk_score"
      ? [
          riskColors.critical,
          riskColors.high,
          riskColors.medium,
          riskColors.low,
          riskColors.info || "",
        ]
      : [
          theme.blue800,
          theme.blue700,
          theme.blue600,
          theme.blue500,
          theme.blue300,
          theme.blue100,
          theme.blue50,
        ];

  const datasets = [
    {
      data: dataWithPercentage.map((dataItem) => dataItem.value),
      backgroundColor: colors,
    },
  ];

  const onClickLegend = (dataItem: DataItem) => {
    Mixpanel.track("Dashboard - Distribution of Assets - redirect", {
      item: dataItem.name,
      distribution: distributionStr,
    });
    const view = {
      name: "",
      filters: getFilter(dataItem.name, distributionStr),
    };
    navigate(`/assets?view=${toBase64AssetsView(view)}`);
  };

  return (
    <Box style={{ width: "100%" }}>
      <Flex column w100 h100 gap="32px">
        <Flex align="center" justify="between" style={{ minWidth: "360px" }}>
          <HeaderSecondary>Distribution of Assets</HeaderSecondary>
          <Dropdown
            width="130px"
            value={distribution}
            onChange={onChangeGroupBy}
            options={assetsDistributionOptions}
          />
        </Flex>
        {distributionStr === "risk_score" ? (
          <AssetsRiskPieChart
            isRiskColors
            filters={assetsFilters}
            size={140}
            labelTextSize={14}
            top={"35px"}
          />
        ) : (
          <Flex w100 h100 align="center" justify="center">
            <Flex w100 justify="center">
              <DoughnutChart
                labels={labels}
                datasets={datasets}
                size={140}
                top={"35px"}
                isLoadingData={isFetching}
                labelNumber={assetsCounts?.total}
                labelText="Assets"
                labelTextSize={14}
                labelNumberSize={
                  assetsCounts?.total && assetsCounts.total > 1000 ? 18 : 24
                }
              />
            </Flex>
            <Flex column w100>
              {dataWithPercentage.map((dataItem, idx) => (
                <LegendItem
                  color={colors[idx]}
                  labelColor={theme.textSub}
                  labelSize={12}
                  label={getLabel(distributionStr, dataItem.name)}
                  value={dataItem.value}
                  onClick={() => {
                    Mixpanel.track(
                      "Dashboard - distribution of assets - redirect",
                      { to: dataItem.name }
                    );
                    onClickLegend(dataItem);
                  }}
                />
              ))}
              {isBreakdownDataLongerThanDisplayed && (
                <TextButton
                  label={"View more"}
                  onClick={() =>
                    navigate(
                      `/insights?distribution=${distributionStr}#assets-distribution`
                    )
                  }
                />
              )}
            </Flex>
          </Flex>
        )}
      </Flex>
    </Box>
  );
};
