import { Filter } from "./AssetsView";

export type Port = {
  closed_at: number; // unix timestamp or 0
  is_open: boolean;
  port_number: number;
  status_code: number;
  webserver: string;
};

export type AssetCoverageStatus =
  | "pt_scheduled"
  | "pending_pt_request"
  | "ongoing_pt"
  | "pt_in_past_6_months"
  | "no_scheduled_or_requested_pt";

export type Technology = {
  vendor: string;
  product: string;
  version: string;
  update: string;
  edition: string;
  sw_edition: string;
  target_sw: string;
  target_hw: string;
  other: string;
  source: string;
  original_string: string; // cpe string
};

export type AssetDomainProperties = {
  cdn_name?: string[];
  certificate?: any;
  cnames?: string[];
  domain_name?: string;
  ip?: string[];
  status_code?: number;
  technologies?: string[];
  technologies_v2?: Technology[];
  webpage_title?: string[];
  webserver?: string[];
  waf?: string[];
  whois?: string;
  country?: string;
  region_name?: string;
  city?: string;
  isp?: string;
  asn?: string;
  asn_description?: string;
  reverse_dns?: string;
};

export type AssetCloudProperties = {
  resource_id?: string;
  resource_name?: string;
  resource_group?: string;
  cloud_provider?: string;
  service?: string;
  region?: string;
};

export type AssetCoverageIssue = "WAF" | "TLS/SSL" | "ASM" | "PT";

export type AssetType = "domain" | "s3_bucket" | "mobile" | "cloud" | "ip";

export const ASSET_TYPES_NAMES: Record<AssetType, string> = {
  domain: "Domain",
  ip: "IPv4 Address",
  mobile: "Mobile",
  cloud: "Cloud",
  s3_bucket: "S3 Bucket",
};

export type AssetSource =
  | "wasp"
  | "aws"
  | "cloudflare"
  | "manual"
  | "azure"
  | "gcp"
  | "other";
export const assetSources = ["wasp", "aws", "cloudflare", "manual"];
// wasp - Asset found by the ASM assets enumerator (AMASS or BBOT)
// manual - Asset was created on the UI

export const assetsEnvs = ["testing", "staging", "development", "production"];

export const cloudProviderOptions = [
  { value: "aws", label: "AWS" },
  { value: "azure", label: "Azure" },
  // { value: "gcp", label: "GCP" },
  // { value: "other", label: "Other" },
];

export type AssetCloudCredentials = {
  // AWS
  access_key_id?: string;
  secret_access_key?: string;

  // Azure
  client_id?: string;
  client_secret?: string;
  tenant_id?: string;

  // GCP
  service_account_key?: string;
};

export interface AssetEdit {
  customer_id?: number;
  name?: string;
  environment?: string;
  is_asm_enabled?: boolean;
  priority?: number;
  product?: number | null;
  parent_asset?: number | null;
  tags?: string[];
  properties?: AssetDomainProperties | AssetCloudProperties;
  type?: AssetType;
  source?: AssetSource;
  is_verified?: boolean;
  schema_content?: string;
  mobile_app_bundle?: File;
  cloud_credentials?: AssetCloudCredentials;
  status?: AssetStatus;
}

export type ConnectionType = "link" | "request";

export type ThirdPartyConnections = {
  domain: string;
  connection_type: ConnectionType;
  url: string;
  tracker_corp?: string;
  tracker_categories?: string[];
  mime_type?: string;
  method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH" | "OPTIONS" | "HEAD";
};

export type AssetStatus = "live" | "dead" | "archived" | "false_positive";

export const assetStatusOptions = [
  { value: "live", label: "Live" },
  { value: "dead", label: "Inactive" },
  { value: "archived", label: "Archived" },
  { value: "false_positive", label: "False Positive" },
];

export interface Asset {
  children_count?: number | null;
  created_at: string;
  customer?: number;
  environment: string;
  id: number;
  is_asm_enabled: boolean;
  name: string;
  parent_asset?: number | null;
  ports_data: Port[];
  priority: number;
  product: number;
  projects: number[];
  properties?: AssetDomainProperties | AssetCloudProperties;
  cloud_credentials?: AssetCloudCredentials;
  related_findings: number[];
  risk_score?: number;
  tags?: string[];
  type: AssetType;
  source: AssetSource;
  coverage_score?: number;
  coverage_issues?: AssetCoverageIssue[];
  updated_at: string;
  last_seen: string;
  registered_at: string;
  is_verified?: boolean;
  third_party_connections?: ThirdPartyConnections[];
  is_schema_saved: boolean;
  mobile_app_bundle_s3_key?: string;
  mobile_app_report_s3_key?: string;
  status?: AssetStatus;
}

export const emptyAsset: Asset = {
  created_at: new Date().toISOString(),
  environment: "",
  id: 0,
  is_asm_enabled: false,
  name: "",
  parent_asset: null,
  ports_data: [],
  priority: 1,
  product: 0,
  projects: [],
  related_findings: [],
  type: "domain",
  source: "manual",
  updated_at: new Date().toISOString(),
  registered_at: "",
  last_seen: "",
  is_verified: false,
  third_party_connections: [],
  is_schema_saved: false,
  status: "live",
};

export type AssetsPage = {
  results: Asset[] | null | undefined;
  count: number | null | undefined;
  next: string | null | undefined;
  previous: string | null | undefined;
};

export type AssetsCounts = {
  newly_discoverd: number;
  total: number;
  total_vulnerable: number;
  is_asm_enabled: number;
};

export const DefaultAssetsCounts: AssetsCounts = {
  newly_discoverd: 0,
  total: 0,
  total_vulnerable: 0,
  is_asm_enabled: 0,
};

export type AssetsRiskDistribution = {
  no_risk: number;
  a: number;
  b: number;
  c: number;
  d: number;
  e: number;
  f: number;
};

export const DefaultAssetsRiskDistribution: AssetsRiskDistribution = {
  no_risk: 0,
  a: 0,
  b: 0,
  c: 0,
  d: 0,
  e: 0,
  f: 0,
};

export type AssetGrade = "A" | "B" | "C" | "D" | "E" | "F";

export type BulkUpdateAssets = {
  //   "all": true/false,
  all: boolean;
  // "filters" will used when "all" is true
  filters?: Filter[];
  //  "assets" will used when "all" is false
  assets?: number[];
  updates: AssetEdit;
};

export interface AssetCompact {
  [key: string]: any;
}

export type AssetCloudConnectionTestResponse = {
  resource_id: string;
  resource_name: string;
  service: string;
};
