import { TabButtonWithCounter } from "../../components/elements/button/tab/TabButtonWithCounter";
import { useApiFindingsCounts } from "../../hooks/queries/findingContext";
import { Filters, tabsFiltersMap } from "./Findings";

type Props = {
  onClick: (tabKey: string) => void;
  selectedTab: string;
  label: string;
  tabKey: string;
  filters?: Filters;
};

export const FindingsQuickFilter = (props: Props) => {
  const { tabKey, selectedTab, label, onClick, filters } = props;

  const countsFilters = filters
    ? { ...filters, ...tabsFiltersMap[tabKey] }
    : tabsFiltersMap[tabKey];

  const { data: findingsCounts } = useApiFindingsCounts(countsFilters);

  // For pending use the pending counter that bypass the default filter (no pending and no false positives)
  const count = tabKey === "pending" ? findingsCounts?.pending : findingsCounts?.total

    return (
    <TabButtonWithCounter
      label={label}
      onClick={onClick}
      count={count}
      tabKey={tabKey}
      selectedTab={selectedTab}
    />
  );
};
