import { Dispatch, SetStateAction, useContext, useMemo, useState } from "react";
import { ThemeContext } from "styled-components";
import { Loading } from "../../../components/elements/loading/Loading";
import { Column, Table } from "../../../components/elements/table/Table";
import {
  BodyRegular,
  BodyBold,
  BodyMedium,
  LabelRegular,
  BodyRegularHover,
} from "../../../components/elements/typography/Typography";
import {
  calculateDaysGapFromNow,
  getDate,
  objectToBase64,
} from "../../../shared/helper";
import { SCREEN_LAPTOP_WIDTH, SEVERITIES } from "../../../shared/consts";
import { Icon } from "../../../components/elements/icon/Icon";
import { SeparatorVertical } from "../../../components/elements/separators/SeparatorVertical";
import { useApiFindingsPaging } from "../../../hooks/queries/findingContext";
import { Finding } from "../../../types/Finding";
import { useScreenWidth } from "../../../hooks/utilsHooks";
import RiskLegendItem from "../../../components/elements/legend/RiskLegendItem";
import ColumnsEditor, {
  getDefaultColumnsState,
} from "../../../components/elements/table/ColumnsEditor";
import { getNumberOfActiveFilters } from "../filters/FindingsFilterBL";
import { IconButton } from "../../../components/elements/button/icon/IconButton";
import { Tooltip } from "../../../components/elements/tooltip/Tooltip";
import { useApiProjects } from "../../../hooks/queries/projectsContext";
import { Assignee } from "../../../components/composed/assignee/Assignee";
import { cvssTooltip } from "../../../shared/tooltipTexts";
import { TableSkeleton } from "../../../components/elements/table/TableSkeleton";
import { FindingStatus } from "../findingStatus/FindingStatus";
import { Link, useSearchParams } from "react-router-dom";
import { getSLATitle } from "../../../shared/findingsHelper";
import { NotFoundBanner } from "../../../components/composed/banner/NotFoundBanner";
import { useApiMe } from "../../../hooks/queries/meContext";
import { findingsDefaultFilters } from "../Findings";
import { FindingsFilterLineTooltip } from "../filters/filterLine/FindingsFilterLineTooltip";
import { CheckboxState } from "../../../components/elements/checkbox/Checkbox";
import { useApiScanners } from "../../../hooks/queries/scannersContext";
import { FindingSync } from "../findingsSync/FindingSync";

export type Props = {
  filters: { [key: string]: any };
  setFilters?: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
  setIsFiltersPaneOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  onSelectedProject?: (finding: Finding) => void;
  onSelectedFinding?: (finding: Finding) => void;
  hasBackToTopButton?: boolean;
  onShowBackToTopButton?: (status: boolean) => void;
  showFiltersButton?: boolean;
  showColumnsEditorButton?: boolean;
  disabledColumns?: string[];
  size?: "small" | "medium";
  isSelectable?: boolean;
  isSelectAll?: boolean;
  setIsSelectAll?: Dispatch<SetStateAction<boolean>>;
  selectedFindings?: Finding[];
  setSelectedFindings?: Dispatch<SetStateAction<Finding[]>>;
  disableSorting?: boolean;
};

export const FindingsTable = (props: Props) => {
  const {
    setIsFiltersPaneOpen,
    onSelectedProject,
    onSelectedFinding,
    hasBackToTopButton,
    onShowBackToTopButton,
    filters,
    setFilters,
    showFiltersButton,
    showColumnsEditorButton,
    disabledColumns,
    size,
    isSelectable,
    isSelectAll,
    setIsSelectAll,
    selectedFindings,
    setSelectedFindings,
    disableSorting,
  } = props;

  const theme = useContext(ThemeContext);
  const screenWidth = useScreenWidth();
  const screenWidthIsLaptop = screenWidth < SCREEN_LAPTOP_WIDTH + 100;
  const { data: me } = useApiMe();

  const { data: scanners } = useApiScanners(me?.customer?.id);

  const scannersDisplayNamesMap = scanners?.reduce<Record<string, string>>(
    (acc, current) => {
      acc[current.name] = current.display_name;
      return acc;
    },
    {}
  );

  const { data: projects } = useApiProjects();
  const {
    data: findings,
    isFetching: isFindingsFetching,
    isRefetching: isFetchingRefetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    status: findingsFetchingStatus,
  } = useApiFindingsPaging(filters || findingsDefaultFilters);

  const [showColumnsEditorPane, setShowColumnsEditorPane] =
    useState<boolean>(false);

  const findingsMapped = useMemo(
    () => findings?.pages.map((page) => page.results || []).flat() || [],
    [findings]
  );

  // Get filters from URL if Findings page or from state if Dashboard
  const [searchParams] = useSearchParams();
  const filtersBase64 = searchParams.get("filters") || objectToBase64(filters);

  const onRowSelect = (row: Finding, state: CheckboxState) => {
    var temp = selectedFindings ? [...selectedFindings] : [];
    if (state === "checked") {
      const finding = findingsMapped?.find((f) => f.id === row.id);
      finding && temp.push(finding);
    } else {
      temp = temp.filter((f) => f.id !== row.id);
    }
    setSelectedFindings && setSelectedFindings(temp);
  };

  const onSelectAll = (rowsId: number[], state: CheckboxState) => {
    if (state === "checked")
      setSelectedFindings && setSelectedFindings(findingsMapped || []);
    else if (state === "unchecked")
      setSelectedFindings && setSelectedFindings([]);
  };

  const columns: Column[] = [
    {
      title: "ID",
      key: "id",
      maxWidth: "70px",
    },
    {
      title: "Finding Title",
      key: "title",
      isRequired: true,
      sortable: !disableSorting,
      minWidth: screenWidthIsLaptop ? "150px" : "272px",
      cell: (row) => (
        <BodyRegularHover
          data-tut="findings-sliders"
          onClick={() => {
            if (!me?.customer.is_locked && onSelectedFinding)
              onSelectedFinding(row.finding);
          }}
          className="text-truncate pointer"
          title={row.title}
        >
          {row.is_pending ? (
            <BodyRegular
              color={theme.redPrimary}
            >{`Pending: ${row.title}`}</BodyRegular>
          ) : (
            row.title
          )}
        </BodyRegularHover>
      ),
    },
    {
      title: "Date Opened",
      key: "created_at",
      maxWidth: "120px",
      sortable: !disableSorting,
      cell: (row) => (
        <BodyRegular>
          {row.created_at ? getDate(row.created_at) : "N/A"}
        </BodyRegular>
      ),
    },
    {
      title: "Severity",
      key: "overall_risk",
      maxWidth: "120px",
      sortable: !disableSorting,

      cell: (row) =>
        me?.customer.is_locked ? (
          <BodyRegular>License Expired.</BodyRegular>
        ) : row.overall_risk || row.overall_risk === 0 ? (
          <RiskLegendItem
            label={SEVERITIES[row.overall_risk]?.toLowerCase()}
            size={14}
          />
        ) : (
          <BodyRegular>N/A</BodyRegular>
        ),
    },
    {
      title: "CVSS",
      key: "cvss_score",
      sortable: !disableSorting,
      maxWidth: "100px",
      titleComponent: () => (
        <span className="d-flex align-items-center justify-content-center">
          <LabelRegular
            style={{
              color: theme.black700,
              fontWeight: filters?.ordering?.includes("cvss_score") ? 700 : 400,
            }}
          >
            CVSS
          </LabelRegular>
          <div
            className="d-flex align-items"
            style={{ marginLeft: "8px", cursor: "pointer" }}
          >
            <Tooltip
              style={{
                width: "300px",
              }}
              content={cvssTooltip}
            >
              <Icon name="info" color={theme.black700} size={16} />
            </Tooltip>
          </div>
        </span>
      ),
      cell: (row) =>
        me?.customer.is_locked ? (
          <BodyRegular>License Expired.</BodyRegular>
        ) : (
          <BodyBold>{row.cvss_score}</BodyBold>
        ),
      centerCell: true,
    },
    {
      title: "Source",
      key: "source",
      cell: (row) =>
        me?.customer.is_locked ? (
          <BodyRegular>License Expired.</BodyRegular>
        ) : !row.is_automated && row.source !== 0 ? (
          <Tooltip
            isTextTruncate
            content={projects?.find((p) => p.id === row.source)?.name || "N/A"}
          >
            <BodyRegularHover
              className="text-truncate pointer"
              onClick={() =>
                onSelectedProject && onSelectedProject(row.finding)
              }
            >
              {projects?.find((p) => p.id === row.source)?.name}
            </BodyRegularHover>
          </Tooltip>
        ) : (
          <Tooltip content={"Automated"} isTextTruncate>
            <div className="d-inline-flex align-items-center gap-2">
              {!screenWidthIsLaptop && (
                <Icon name="wasp" color={theme.primary} size={24} />
              )}
              <BodyRegular className="d-block text-truncate">
                Automated
              </BodyRegular>
            </div>
          </Tooltip>
        ),
    },
    {
      title: "Source Scanner",
      key: "scanner_name",
      minWidth: "180px",
      cell: (row) => (
        <BodyRegular>
          {scannersDisplayNamesMap && scannersDisplayNamesMap[row.scanner_name]}
        </BodyRegular>
      ),
    },
    {
      title: "Issued By",
      key: "issued_by",
      maxWidth: "180px",
      cell: (row) => (
        <Assignee
          imageSrc={row.op_jira_assignee?.avatar_url}
          name={row.op_jira_assignee?.display_name}
          isEditable={false}
          hideIcon={screenWidthIsLaptop}
          isDisabled={!row.op_jira_assignee}
          findingId={row.id}
        />
      ),
    },
    {
      title: "Assigned To",
      key: "assigned_to",
      maxWidth: "180px",
      cell: (row) =>
        me?.customer.is_locked ? (
          <BodyRegular>License Expired.</BodyRegular>
        ) : (
          <Assignee
            imageSrc={row.customer_jira_assignee?.avatar_url}
            name={row.customer_jira_assignee?.display_name}
            isEditable={true}
            hideIcon={screenWidthIsLaptop}
            // isDisabled={!row.customer_jira_issue_key}
            findingId={row.id}
          />
        ),
    },
    {
      title: "SLA",
      key: "sla_expires_at",
      sortable: !disableSorting,
      maxWidth: "120px",
      cell: (row) => {
        const sla_expires_at = row.sla_expires_at
          ? new Date(row.sla_expires_at)
          : null;
        if (!sla_expires_at) {
          return (
            <div style={{ color: "#D9D9D9" }} className="center">
              <BodyMedium>-</BodyMedium>
            </div>
          );
        }

        const leftDays = calculateDaysGapFromNow(sla_expires_at);
        const title = getSLATitle(leftDays);

        return (
          <div className="d-flex align-items-center justify-content-between w-100">
            <BodyMedium
              className="text-truncate"
              title={title}
              style={{
                color: (leftDays || 10) < 4 ? theme.redPrimary : theme.black600,
              }}
            >
              {title}
            </BodyMedium>
          </div>
        );
      },
    },
    {
      title: "External Ticket",
      key: "customer_jira_issue_key",
      maxWidth: "180px",
      cell: (row) => <FindingSync finding={row.finding} />,
    },
    {
      title: "Status",
      key: "status",
      maxWidth: "180px",
      sortable: !disableSorting,
      cell: (row) => <FindingStatus finding={row.finding} />,
    },
    {
      title: "Comments",
      key: "comments_count",
      sortable: !disableSorting,
      maxWidth: "100px",
      cell: (row) => (
        <BodyRegular className="text-center w-100">
          {row.comments_count}
        </BodyRegular>
      ),
    },
    {
      title: "",
      key: "",
      isRequired: true,
      iconName: showColumnsEditorButton ? "write" : "",
      onTitleClick: () =>
        showColumnsEditorButton && setShowColumnsEditorPane(true),
      maxWidth: "50px",
      cell: (row) => (
        <Link
          data-tut="findings-page"
          to={`/finding-details/${row.finding.id}?filters=${filtersBase64}`}
        >
          <IconButton
            iconName="chevronRight"
            size="small"
            dataTestId={`open-finding-${row.id}`}
            onClick={() => {}}
          />
        </Link>
      ),
    },
  ];

  // Handle columns state editing
  const storedStateStr = localStorage.getItem("findingTableColumnsState") || "";
  // If no saved state use default columns state (first 10)
  const savedColumnsState = storedStateStr
    ? JSON.parse(storedStateStr)
    : getDefaultColumnsState(true, columns, 10);

  columns?.forEach((col) =>
    savedColumnsState
      ? (col.isHidden = !savedColumnsState[col.title])
      : (col.isHidden = false)
  );

  // Remove disabled columns
  columns.forEach((col) => {
    if (disabledColumns?.includes(col.key)) {
      col.isHidden = true;
    }
  });

  const rows = findingsMapped.map((finding, idx) => ({
    idx,
    id: finding.id,
    title: finding.title,
    created_at: finding.date_found || finding.created_at,
    overall_risk: finding.overall_risk,
    status: finding.status,
    breached_sla: "",
    sla_expires_at: finding.sla_expires_at,
    finding: finding,
    comments_count: finding.comments_count,
    source: finding.project,
    cvss_score: finding.cvss_score,
    customer_jira_assignee: finding.customer_jira_assignee,
    op_jira_assignee: finding.op_jira_assignee,
    is_automated: finding.is_automated,
    customer_jira_issue_key: finding.customer_jira_issue_key,
    is_pending: !!finding.is_pending,
    scanner_name: finding.scanner_name,
  }));

  if (findingsFetchingStatus === "loading")
    return (
      <div style={{ height: "100%" }}>
        <TableSkeleton />
      </div>
    );

  return (
    <div style={{ height: "100%" }}>
      {isFindingsFetching && !isFetchingNextPage && !isFetchingRefetching && (
        <div>
          <Loading />
        </div>
      )}

      {showFiltersButton ? (
        <div
          className="pointer d-flex align-items-center gap-6"
          style={{
            position: "absolute",
            right: "25px",
            top: "30px",
          }}
          data-testid="findings-filters-button"
          onClick={() => setIsFiltersPaneOpen && setIsFiltersPaneOpen(true)}
        >
          <SeparatorVertical style={{ height: "24px" }} />
          <Tooltip
            placement="left"
            content={<FindingsFilterLineTooltip filters={filters} />}
          >
            <>
              {getNumberOfActiveFilters(filters) > 0 && (
                <div
                  data-testid="findings-filters-count"
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    fontSize: "12px",
                    fontWeight: 700,
                    color: theme.blue,
                    backgroundColor: theme.blue100,
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                  }}
                >
                  {getNumberOfActiveFilters(filters)}
                </div>
              )}
            </>
          </Tooltip>
          <Icon name="filter" size={26} color={theme.primary} />
        </div>
      ) : null}
      {showColumnsEditorPane && (
        <ColumnsEditor
          onClose={() => setShowColumnsEditorPane(false)}
          columns={columns}
          stateStorageKey="findingTableColumnsState"
        />
      )}
      <div style={{ position: "relative", paddingBottom: "120px" }}>
        {findingsMapped.length === 0 && !isFindingsFetching ? (
          <div className="d-flex flex-column justify-content-center align-items-center w-100 mt-2">
            <NotFoundBanner
              title="No findings matched the selected Filters!"
              text="Sorry, no findings matched the filters applied. Please try changing the filters."
            />
          </div>
        ) : (
          <Table
            columns={columns}
            rows={rows}
            minWidth={560}
            hasScrollPagination
            hasBackToTopButton={hasBackToTopButton}
            onShowBackToTopButton={onShowBackToTopButton}
            isFetchingNextPage={isFetchingNextPage}
            onScrollPagination={fetchNextPage}
            hasNextPage={hasNextPage}
            defaultSortKey="overall_risk"
            defaultSortOrder="desc"
            onSort={(column, order) =>
              setFilters &&
              setFilters({
                ...filters,
                ordering: order === "desc" ? `-${column.key}` : column.key,
              })
            }
            size={size}
            isSelectable={isSelectable}
            selectedRows={selectedFindings}
            onRowSelect={onRowSelect}
            onSelectAll={onSelectAll}
            isSelectAll={isSelectAll}
            setIsSelectAll={setIsSelectAll}
            isLoading={isFindingsFetching}
          />
        )}
      </div>
    </div>
  );
};
