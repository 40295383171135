import { useContext } from "react";
import { Flex } from "../../components/layouts/flex/Flex";
import { ASMUpdatesTimelineItem } from "./ASMUpdatesTimelineItem";
import { Timeline } from "../../components/elements/timeline/Timeline";
import { Icon } from "../../components/elements/icon/Icon";
import { ThemeContext } from "styled-components";
import { useApiScanSummariesPaging } from "../../hooks/queries/scansContext";
import { useApiMe } from "../../hooks/queries/meContext";
import { ASMUpdatesTimelineSkeleton } from "./ASMUpdatesTimelineSkeleton";
import { ScanSummary } from "../../types/ScanSummary";
import { ASMUpdatesNoFindingsTimelineItem } from "./ASMUpdatesNoFindingsTimelineItem";
import { useApiFindings } from "../../hooks/queries/findingContext";
import { useApiScanners } from "../../hooks/queries/scannersContext";

export const ASMUpdatesTimeline = () => {
  const theme = useContext(ThemeContext);
  const { data: me } = useApiMe();
  const { data: scanners, isFetching: isFetchingScanners } = useApiScanners(
    me?.customer?.id
  );

  const { data: scanSummariesPages, isFetching: isFetchingScanSummaries } =
    useApiScanSummariesPaging(
      {
        customer_id: me?.customer?.id,
        page_size: 10,
        total_reported_findings_gt: 0,
        scan_end_time_after: new Date(2024, 4, 24).toISOString(),
      },
      true,
      0,
      me
    );

  const getAssetsSummaries = (): ScanSummary[] => {
    return (
      scanSummariesPages?.pages?.map((p) => p?.results || [])?.flat() || []
    );
  };
  const scanSummaries = getAssetsSummaries();

  const scanIds = [...new Set(scanSummaries?.map((as) => as.scan_id) || [])];

  const { data: findings, isFetching: isFindingsFetching } = useApiFindings(
    {
      scan_id: scanIds.join(","),
      is_automated: true,
      include_pending: true,
      include_false_positive: true,
      page_size: 500,
    },
    scanIds.length > 0
  );

  const isInTheSame24Hours = (date1: Date, date2: Date): boolean => {
    const diffInMilliseconds = Math.abs(date1.getTime() - date2.getTime());
    const diffInHours = diffInMilliseconds / (1000 * 60 * 60);
    return diffInHours <= 24;
  };

  var lastScanSummaryWithoutFindingsDate = null;

  if (
    scanSummaries.length > 0 &&
    !isInTheSame24Hours(
      new Date(),
      new Date(scanSummaries.at(0)?.scan_end_time || "")
    )
  ) {
    lastScanSummaryWithoutFindingsDate = new Date(
      scanSummaries.at(0)?.scan_end_time || ""
    );
    lastScanSummaryWithoutFindingsDate.setDate(
      lastScanSummaryWithoutFindingsDate.getDate() + 1
    );
  }

  const getInProgressFindings = (scanSummary: ScanSummary): number => {
    const scanFindings =
      findings?.filter((finding) => finding.scan_id === scanSummary.scan_id) ||
      [];
    const pendingFindings = scanFindings.filter(
      (finding) => finding.is_pending
    );

    return (
      scanSummary.total_reported_findings -
      scanFindings.length +
      pendingFindings.length
    );
  };

  const getIconString = (scanSummary: ScanSummary): string => {
    if (
      scanSummary.scanner === "sublister" ||
      !scanSummary.total_reported_findings
    ) {
      return "info";
    } else if (getInProgressFindings(scanSummary) > 0) {
      return "in_progress";
    }
    return "fixed";
  };

  const getColor = (scanSummary: ScanSummary): string => {
    if (
      scanSummary.scanner === "sublister" ||
      !scanSummary.total_reported_findings
    ) {
      return theme.grayBluePrimary;
    } else if (getInProgressFindings(scanSummary) > 0) {
      return theme.primary;
    }
    return theme.greenPrimary;
  };

  var entries =
    scanSummaries.map((scanSummary, i) => ({
      element: (
        <ASMUpdatesTimelineItem
          key={`scan-event-timeline-${i}`}
          scanSummary={scanSummary}
          scanners={scanners || []}
          findings={
            findings?.filter(
              (finding) => finding.scan_id === scanSummary.scan_id
            ) || []
          }
          inProgressFindings={getInProgressFindings(scanSummary)}
          hideDate={
            i > 0 &&
            isInTheSame24Hours(
              new Date(scanSummaries[i - 1].scan_end_time),
              new Date(scanSummaries[i].scan_end_time)
            )
          }
        />
      ),
      icon: (
        <Icon
          name={getIconString(scanSummary)}
          size={24}
          color={getColor(scanSummary)}
        />
      ),
    })) || [];

  if (!!lastScanSummaryWithoutFindingsDate) {
    entries.unshift({
      element: (
        <ASMUpdatesNoFindingsTimelineItem
          date={lastScanSummaryWithoutFindingsDate}
        />
      ),
      icon: <Icon name={"total"} size={24} color={theme.black600} />,
    });
  }

  return (
    <Flex column style={{ padding: "0 3px 0 6px" }}>
      {isFetchingScanSummaries || isFetchingScanners || isFindingsFetching ? (
        <ASMUpdatesTimelineSkeleton />
      ) : (
        <Timeline entries={entries} />
      )}
    </Flex>
  );
};
