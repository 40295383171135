import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { DEFAULT_STALE_TIME, invalidateApiQueries } from "./utils";
import { createItem, getPage, deleteUrl, getSingleItem, getItems } from "./sdk";
import { ScanEvent, ScanEventPage } from "../../types/ScanEvent";
import { bitAfterNow, getQueryParams } from "../../shared/helper";
import { ScanSummary, ScanSummaryPage } from "../../types/ScanSummary";
import { IS_DEMO_ENV } from "./utils";
import { mockScanEvents } from "../../mocks/scan_events";
import { mockScanSummaries } from "../../mocks/scan_summaries";
import { Me } from "../../types/Me";

const key = "scan-events";
const scanKey = "scan";
const summaryKey = "scan-summary";
// All possible attributes that can pass to API call

export interface ScanContext {
  customerId: number;
  scannerName?: string;
  customerName?: string;
  username?: string;
  onSuccessCallback: (scanRunParams: ScanEvent) => void;
  onErrorCallback: (error: Error) => void;
}

// Returns a single scan summary object
export const useApiScanSummary = (scanId: string) =>
  useQuery<ScanSummary | undefined, Error>({
    queryKey: [summaryKey, scanId],
    placeholderData: undefined,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    // enabled:!!scanId,
    queryFn: async (): Promise<ScanSummary | undefined> =>
      getSingleItem(summaryKey, scanId) as Promise<ScanSummary | undefined>,
  });

// Returns a list of scan summary object
export const useApiScanSummaries = (
  filters?: { [key: string]: any },
  enabled: boolean = true
) =>
  useQuery<ScanSummary[] | undefined, Error>({
    queryKey: [summaryKey, filters],
    placeholderData: undefined,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled,
    queryFn: async (): Promise<ScanSummary[] | undefined> =>
      getItems(summaryKey, filters) as Promise<ScanSummary[] | undefined>,
  });

export const useApiScanEventsPaging = (
  filters?: { [key: string]: any },
  enabled: boolean = true,
  refetchInterval: number = 0
) =>
  useInfiniteQuery<ScanEventPage, Error>({
    queryKey: [key, filters],
    keepPreviousData: true,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: enabled,
    refetchInterval: refetchInterval,
    queryFn: async ({ pageParam = 1 }): Promise<ScanEventPage> => {
      if (IS_DEMO_ENV) return mockScanEvents;
      return getPage(key, { ...filters, page: pageParam });
    },
    getNextPageParam: (lastPage, allPages) =>
      lastPage.next
        ? parseInt(getQueryParams(lastPage.next)?.page || "1")
        : null,
  });

export const useApiScanSummariesPaging = (
  filters?: { [key: string]: any },
  enabled: boolean = true,
  refetchInterval: number = 0,
  me?: Me
) =>
  useInfiniteQuery<ScanSummaryPage, Error>({
    queryKey: [summaryKey, filters],
    keepPreviousData: true,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: enabled,
    refetchInterval: refetchInterval,
    queryFn: async ({ pageParam = 1 }): Promise<ScanSummaryPage> => {
      if (IS_DEMO_ENV || me?.customer.license?.type === "admin")
        return mockScanSummaries;
      return getPage(summaryKey, { ...filters, page: pageParam });
    },
    getNextPageParam: (lastPage, allPages) =>
      lastPage.next
        ? parseInt(getQueryParams(lastPage.next)?.page || "1")
        : null,
  });

export const useApiRunScan = () => {
  const queryClient = useQueryClient();
  return useMutation<ScanEvent, Error, ScanContext>({
    mutationKey: [scanKey],
    mutationFn: async (newScanEvent): Promise<ScanEvent> =>
      await createItem(
        `${scanKey}?${new URLSearchParams({
          customer_id: newScanEvent.customerId?.toString() || "",
          scanner: newScanEvent.scannerName || "",
        })}`,
        {}
      ),
    onSuccess: (data, { onSuccessCallback }) =>
      onSuccessCallback && onSuccessCallback(data),
    onSettled: () => invalidateApiQueries([key], queryClient),

    onError: (error: Error, { onErrorCallback }) => {
      onErrorCallback && onErrorCallback(error);
    },
  });
};

export const useApiStopScan = () => {
  const queryClient = useQueryClient();
  return useMutation<ScanEvent, Error, ScanContext>({
    mutationKey: [scanKey],
    mutationFn: async (scanStopParams): Promise<any> =>
      await deleteUrl(scanKey, {
        customer_id: scanStopParams.customerId.toString(),
        customer_name: scanStopParams.customerName || "",
        username: scanStopParams.username || "",
        scanner: scanStopParams.scannerName || "",
      }),
    onSuccess: (data, { onSuccessCallback }) =>
      onSuccessCallback && onSuccessCallback(data),
    onSettled: () => invalidateApiQueries([key], queryClient),

    onError: (error: Error, { onErrorCallback }) => {
      onErrorCallback && onErrorCallback(error);
    },
  });
};
