import { useNavigate } from "react-router";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import { RightPane } from "../../../components/elements/rightPane/RightPane";
import { HeaderSecondary } from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { Filter } from "../../../types/AssetsView";
import { AssetsTable } from "../../assets/assetsTable/AssetsTable";
import { FindingsTable } from "../../findings/findingsTable/FindingsTable";
import { ActionItem } from "./BenchmarkOverTime";
import { objectToBase64, toBase64AssetsView } from "../../../shared/helper";

type Props = {
  onClose: () => void;
  action: ActionItem;
};

export const ActionsPane = (props: Props) => {
  const { onClose, action } = props;

  const navigate = useNavigate();

  const actionTarget: "assets" | "findings" = action.url.includes("/assets")
    ? "assets"
    : "findings";

  return (
    <RightPane onClose={onClose} width="1200px">
      <Flex
        gap="24px"
        column
        style={{ height: "100vh", padding: "24px", overflowY: "scroll" }}
      >
        <Flex align="center" justify="between">
          <HeaderSecondary>{action.title}</HeaderSecondary>
          {actionTarget === "assets" ? (
            <MainButton
              label="View on Assets Page"
              onClick={() =>
                navigate(
                  `/assets?view=${toBase64AssetsView({ name: "", filters: action.filters as Filter[] })}`
                )
              }
            />
          ) : (
            <MainButton
              label="View on Findings Page"
              onClick={() =>
                navigate(`/findings?filters=${objectToBase64(action.filters)}`)
              }
            />
          )}
        </Flex>
        {actionTarget === "assets" ? (
          <AssetsTable
            filters={action.filters as Filter[]}
            stateStorageKey="benchmarkActionsTable"
          />
        ) : (
          <FindingsTable
            onSelectedFinding={() => {}}
            filters={action.filters}
          />
        )}
      </Flex>
    </RightPane>
  );
};
