import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { InputStyle } from "../input/InputStyle";

type Props = {
  disabled?: boolean;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  width?: string | number;
  dataTestId?: string;
  max?: string;
  min?: string;
  isError?: boolean;
};

export const DatePicker = (props: Props) => {
  const {
    disabled,
    value,
    onChange,
    placeholder,
    width,
    dataTestId,
    max,
    min,
    isError,
  } = props;

  const theme = useContext(ThemeContext);

  return (
    <InputStyle
      type={"date"}
      max={max}
      min={min}
      disabled={disabled}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      width={width}
      data-testid={dataTestId}
      style={{ colorScheme: theme.name }}
      isError={isError}
    />
  );
};
