import { useQuery } from "@tanstack/react-query";
import { DEFAULT_STALE_TIME } from "../../../hooks/queries/utils";
import { bitAfterNow } from "../../../shared/helper";
import { getItems } from "../../../hooks/queries/sdk";

export const keyFindingTrends = "findings-trends";
export const keyProducts = "products-scores";
export const keySecurityScoreExtended = "security-score-extended";

type findingsTrend = {
  date: string;
  total_count: number;
};

type prodScore = {
  risk_score: number;
  product_id: number;
};

type securityScoreExtended = {
  security_score: number;
  urgent_findings: number;
  highly_vulnerable_assets: number;
  // protection_coverage: number;
  asm_disabled_assets: number;
  unassigned_findings: number;
  widespread_findings: number;
};

type AssetsRiskBreakdown = {
  critical: number;
  high: number;
  medium: number;
  low: number;
  info: number;
  no_risk: number;
};

type AssetsOverview = {
  current: AssetsRiskBreakdown;
  timeframe: AssetsRiskBreakdown;
  current_coverage: number;
  timeframe_coverage: number;
};

export const useApiFindingsTrendsCharts = (filters?: { [key: string]: any }) =>
  useQuery<findingsTrend[], Error>({
    queryKey: [keyFindingTrends, filters],
    keepPreviousData: true,
    placeholderData: undefined,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: true,
    queryFn: async (): Promise<findingsTrend[]> =>
      getItems(keyFindingTrends, filters),
  });

export const useApiProductsRiskScores = () =>
  useQuery<prodScore[], Error>({
    queryKey: [keyFindingTrends, keyProducts],
    keepPreviousData: true,
    placeholderData: undefined,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: true,
    queryFn: async (): Promise<prodScore[]> =>
      getItems(`${keyFindingTrends}/${keyProducts}`),
  });

export const useApiSecurityScoreExtended = (params?: any) =>
  useQuery<securityScoreExtended, Error>({
    queryKey: [keyFindingTrends, keySecurityScoreExtended, params],
    keepPreviousData: true,
    placeholderData: undefined,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: true,
    queryFn: async (): Promise<securityScoreExtended> =>
      getItems(`${keyFindingTrends}/${keySecurityScoreExtended}`, params),
  });

export const useApiAssetsOverview = (params?: any) =>
  useQuery<AssetsOverview, Error>({
    queryKey: [keyFindingTrends, "assets-overview", params],
    keepPreviousData: true,
    placeholderData: undefined,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: true,
    queryFn: async (): Promise<any> =>
      getItems(`${keyFindingTrends}/assets-overview`, params),
  });
