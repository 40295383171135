import { Dispatch, SetStateAction, useContext, useRef, useState } from "react";
import { ThemeContext } from "styled-components";
import {
  useApiFindingsAssignedUsers,
  useApiUpdateFindingAssignee,
} from "../../../hooks/queries/jiraIntegrationContext";
import useToastContext from "../../../hooks/toastHook";
import {
  useMutationWithDelay,
  useOutsideTrigger,
} from "../../../hooks/utilsHooks";
import { FindingEdit, JiraUser } from "../../../types/Finding";
import { Avatar } from "../../elements/avatar/Avatar";
import { LinkButton } from "../../elements/button/link/LinkButton";
import { InputText } from "../../elements/input/textInput/InputText";
import { Loading } from "../../elements/loading/Loading";
import { Menu } from "../../elements/menu/Menu";
import { SeparatorHorizontal } from "../../elements/separators/SeparatorHorizontal";
import { LabelRegular } from "../../elements/typography/Typography";
import { AssigneeItemStyle } from "./AssigneeStyles";
import { Flex } from "../../layouts/flex/Flex";
import { Backdrop } from "../../elements/rightPane/RightPane";
import { useApiAccounts } from "../../../hooks/queries/accountsContext";
import { useApiMe } from "../../../hooks/queries/meContext";

type Props = {
  close: () => void;
  findingId?: number | undefined;
  setIsUpdating?: Dispatch<SetStateAction<boolean>>;
  setShowConfirmModal?: Dispatch<SetStateAction<boolean>>;
  setUpdates?: Dispatch<SetStateAction<FindingEdit | null>>;
};

export const AssigneeMenu = (props: Props) => {
  const { close, findingId, setIsUpdating, setShowConfirmModal, setUpdates } =
    props;

  const theme = useContext(ThemeContext);
  const wrapperRef = useRef(null);
  useOutsideTrigger(wrapperRef, close);

  const [searchText, setSearchText] = useState<string>("");

  const { data: me } = useApiMe();

  const { data: jiraUsers, isFetching } =
    useApiFindingsAssignedUsers(searchText);

  const { data: accounts } = useApiAccounts({ email: searchText });

  const waspUsers =
    accounts?.map((account) => ({
      display_name: account.user.name,
      email: account.email,
      avatar_url: account.user.avatar_url,
    })) || [];

  const users = me?.customer.jira_details?.token ? jiraUsers : waspUsers;

  const { runMutation } = useMutationWithDelay(3000);
  const addToast = useToastContext();

  const { mutate: updateAssignee } = useApiUpdateFindingAssignee();

  const handleAssignUser = (jiraUser: JiraUser) => {
    if (!findingId && !setShowConfirmModal) {
      addToast({ message: `Missing finding id`, type: "error" });
      return;
    }
    if (setShowConfirmModal && setUpdates) {
      setUpdates({ assignee: jiraUser });
      setShowConfirmModal(true);
      return;
    }
    setIsUpdating && setIsUpdating(true);
    runMutation(
      () =>
        updateAssignee({
          findingId: findingId || -1,
          jiraUser: { ...jiraUser },
          onSuccessCallback: () => {
            addToast({
              message: !jiraUser.display_name
                ? "Successfully unassigned"
                : `Successfully updated assignee "${jiraUser.display_name}"`,
              type: "success",
            });
            setIsUpdating && setIsUpdating(false);
          },
          onErrorCallback: () => {
            addToast({
              message: !jiraUser.display_name
                ? "Failed to unassign ticket"
                : `Failed to update assignee "${jiraUser.display_name}"`,
              type: "error",
            });
            setIsUpdating && setIsUpdating(false);
          },
        }),
      !jiraUser.display_name
        ? "Unassign ticket..."
        : `Updating assignee "${jiraUser.display_name}"`
    );
    close();
  };

  return (
    <>
      <Backdrop
        transparent
        blurEnabled={false}
        onClick={close}
        style={{
          position: "absolute",
          left: "-100vw",
          top: "-100vh",
          bottom: "-100vh",
          right: "-100vw",
        }}
      />
      <Menu
        ref={wrapperRef}
        style={{
          width: "300px",
          zIndex: 200,
          padding: "16px",
        }}
      >
        <Flex column w100 gap="8px">
          <Flex column w100 gap="8px">
            <LabelRegular style={{ color: theme.black600 }}>
              Assign ticket to...
            </LabelRegular>
            <InputText
              placeholder="Search"
              iconName="search"
              onChange={(e) => setSearchText(e.target.value)}
              width={"100%"}
            />
            <LinkButton
              label="Unassign"
              size="small"
              iconColor={theme.primary}
              iconName="cancel"
              noPadding
              onClick={() =>
                handleAssignUser({
                  jira_id: "",
                  display_name: "",
                  email: "",
                  avatar_url: "",
                })
              }
            />
          </Flex>

          <SeparatorHorizontal />
          {isFetching ? <Loading /> : <></>}
          <Flex
            column
            w100
            gap="16px"
            style={{ height: "295px", overflowY: "auto" }}
          >
            {users?.map((user) =>
              user.display_name ? (
                <Flex
                  align="center"
                  gap="4px"
                  key={`user-item-${user.email}`}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleAssignUser(user as JiraUser)}
                >
                  <Avatar size={24} imageSrc={user.avatar_url} />

                  <AssigneeItemStyle
                    className="text-truncate"
                    style={{ color: theme.black800 }}
                  >
                    {user.display_name}
                  </AssigneeItemStyle>
                </Flex>
              ) : null
            )}
          </Flex>
        </Flex>
      </Menu>
    </>
  );
};
