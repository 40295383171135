import { useContext } from "react";
import { Flex } from "../../../components/layouts/flex/Flex";
import {
  HeaderMain,
  LabelMini,
} from "../../../components/elements/typography/Typography";
import "chart.js/auto";
import { Doughnut } from "react-chartjs-2";
import { ThemeContext } from "styled-components";
import { getTooltip } from "./externalTooltip";
import { KPI } from "../kpi/KPI";

export type DoughnutChartDataSet = {
  data: number[];
  backgroundColor: string[];
};

export const pieChartSkeletonData = (color?: string) => ({
  labels: [],
  datasets: [
    {
      label: "",
      data: [1],
      backgroundColor: color || "#E6E6E6", //black500
      borderColor: color || "#E6E6E6",
    },
  ],
});

type Props = {
  labelNumber?: number; // The main counter in the chart center
  labelSuffix?: string;
  labelText?: string; // The main counter label in the chart center
  isLoadingData?: boolean;
  labels: string[];
  datasets: DoughnutChartDataSet[];
  size?: number;
  labelNumberSize?: number;
  labelTextSize?: number;
  top?: string;
  labelNumberLineHeight?: string;
};

export const DoughnutChart = ({
  labelNumber,
  labelSuffix,
  labelText,
  isLoadingData,
  labels,
  datasets,
  size,
  labelNumberSize,
  labelTextSize,
  top,
  labelNumberLineHeight = "48px",
}: Props) => {
  const theme = useContext(ThemeContext);
  return (
    // CHART CONTAINER
    <div
      style={{
        position: "relative",
        width: `${size || 100}px`,
        height: `${size || 100}px`,
      }}
    >
      {/* INNER KPI */}
      <Flex
        column
        w100
        className="text-center"
        justify="center"
        align="center"
        style={{
          position: "absolute",
          top: top || "20%",
          left: "16%",
          width: "70%",
          borderRadius: "50%",
          zIndex: 1,
        }}
      >
        <Flex>
          <KPI
            count={labelNumber}
            size="large"
            style={{
              lineHeight: labelNumberLineHeight,
              fontSize: labelNumberSize || "",
            }}
          />
          {!!labelSuffix ? (
            <HeaderMain
              style={{
                lineHeight: labelNumberLineHeight,
                fontSize: labelNumberSize ? labelNumberSize - 7 : "",
              }}
            >
              {labelSuffix}
            </HeaderMain>
          ) : (
            ""
          )}
        </Flex>
        <LabelMini
          className="text-truncate w-100"
          title={labelText}
          style={{
            fontSize: labelTextSize || "",
          }}
        >
          {labelText}
        </LabelMini>
      </Flex>
      {/* CHART */}
      <div style={{ zIndex: 10 }}>
        <Doughnut
          options={{
            cutout: "75%",
            elements: { arc: { borderColor: theme.bg2 } },
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                enabled: false,
                mode: "point",
                external: (context: any) => getTooltip(context, theme, "bar"),
                usePointStyle: true,
              },
            },
          }}
          data={
            isLoadingData
              ? pieChartSkeletonData(theme.black500)
              : { labels: labels, datasets: datasets }
          }
        />
      </div>
    </div>
  );
};
