import { useContext } from "react";
import { Box } from "../../../components/elements/box/Box";
import { Asset, AssetDomainProperties, Technology } from "../../../types/Asset";
import { Badge } from "../../../components/elements/badge/Badge";
import { ThemeContext } from "styled-components";
import { Flex } from "../../../components/layouts/flex/Flex";
import {
  LabelBold,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { Tooltip } from "../../../components/elements/tooltip/Tooltip";

type Props = {
  asset: Asset | undefined;
  width?: string;
};

export const AssetTech = (props: Props) => {
  const { asset, width } = props;
  const theme = useContext(ThemeContext);

  if (asset?.type !== "domain") {
    return null;
  }

  const TechnologyTooltip = ({ tech }: { tech: Technology }) => {
    return (
      <Flex column gap="4px">
        <Flex align="center">
          <LabelBold style={{ width: "100px" }}>Product:</LabelBold>
          <LabelRegular className="text-truncate w-100">
            {tech.product}
          </LabelRegular>
        </Flex>
        <Flex align="center">
          <LabelBold style={{ width: "100px" }}>Vendor:</LabelBold>
          <LabelRegular className="text-truncate w-100">
            {tech.vendor}
          </LabelRegular>
        </Flex>
        <Flex align="center">
          <LabelBold style={{ width: "100px" }}>Version</LabelBold>
          <LabelRegular className="text-truncate w-100">
            {tech.version}
          </LabelRegular>
        </Flex>
      </Flex>
    );
  };

  return (
    <Box style={{ width }}>
      <Flex column gap="12px">
        <LabelRegular className="text-truncate w-100">
          Technologies
        </LabelRegular>
        <Flex flexWrap gap="8px">
          {(asset?.properties as AssetDomainProperties)?.technologies_v2
            ?.filter((t) => !!t.product)
            .map((tech: Technology) => (
              <Tooltip content={<TechnologyTooltip tech={tech} />}>
                <Badge
                  key={`tech-item-${tech}`}
                  style={{
                    backgroundColor: theme.blue100,
                    color: theme.blue700,
                  }}
                >
                  {tech.product}
                </Badge>
              </Tooltip>
            ))}
        </Flex>
      </Flex>
    </Box>
  );
};
